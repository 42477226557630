import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AmbGrid from '../../common/AmbGrid';
import * as etaActions from '../../../redux/actions/eta.actions';
import * as willCallActions from '../../../redux/actions/willCall.actions';
import GetETAButton from './GetETAButton.frame';
import { openMedicalNecessityModal } from '../../MedicalNecessityStepper/redux/openMedicalNecessityModal';
import { needsMedicalNecessityQuestionnaire } from '../../../flags/mnq.flag';
import { needsPCSForm } from '../../../flags/pcs.flag';
import { openPCSModal } from '../../PhysicianCertificationStatement/redux/openPCSModal';

function GetEtaButtonContainer(props) {
  const handleGetEtaClick = () => {
    setTimeout(() => {
      if (props.needsMedicalNecessityQuestionnaire) {
        props.openMedicalNecessityModal(true);
      } else if (props.needsPCSForm) {
        props.openPCSModal(true);
      } else {
        props.clickGetETA();
      }
    }, 100);
  };

  return (
    <AmbGrid style={{ alignSelf: 'center' }}>
      <GetETAButton {...props} handleGetEtaClick={handleGetEtaClick} />
    </AmbGrid>
  );
}

function mapStateToProps(state) {
  return {
    isDisabled: state.eta.isDisabled || !state.app.online,
    loadedWillCall: state.requests.loadedWillCall,
    isVisible: !(state.eta.pollInProgress || state.formFlow.acceptETAInProgress),
    needsMedicalNecessityQuestionnaire: needsMedicalNecessityQuestionnaire(state),
    needsPCSForm: needsPCSForm(state),
    isNonTransport: state.requests.isNonTransport,
  };
}

GetEtaButtonContainer.propTypes = {
  needsMedicalNecessityQuestionnaire: PropTypes.bool.isRequired,
  needsPCSForm: PropTypes.bool.isRequired,
  openMedicalNecessityModal: PropTypes.func.isRequired,
  clickGetETA: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  ...etaActions,
  ...willCallActions,
  openMedicalNecessityModal,
  openPCSModal,
})(GetEtaButtonContainer);
