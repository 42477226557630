import { createAction } from '@reduxjs/toolkit';

export const getPcrStatus = createAction('[Request] getPcrStatus');
export const setPcrStatus = createAction('[Request] setPcrStatus');
export const getPcrStatusSuccess = createAction('[Request] getPcrStatusSuccess');
export const selectPickupDepartment = createAction('[Request] selectPickupDepartment');
export const selectDestinationDepartment = createAction('[Request] selectDestinationDepartment');
export const clearPickupDepartment = createAction('[Request] clearPickupDepartment');
export const clearDestinationDepartment = createAction('[Request] clearDestinationDepartment');
export const setPickupDangerousFlag = createAction('[Location] setPickupDangerousFlag');
export const unsetPickupDangerousFlag = createAction('[Location] unsetPickupDangerousFlag');
export const setDestinationDangerousFlag = createAction('[Location] setDestinationDangerousFlag');
export const unsetDestinationDangerousFlag = createAction('[Location] unsetDestinationDangerousFlag');
