import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as requestsActions from '../../redux/actions/requests.actions';
import * as serviceActions from '../../redux/actions/service.actions';
import * as locationActions from '../../redux/actions/location.actions';
import * as infoboxActions from '../../redux/actions/infobox.actions';
import * as formFlowActions from '../../redux/actions/formFlow.actions';
import MedicalService from './MedicalService.validations';
import { transportReasonCollapse } from 'flags/collapse.flag';

const getTelehealthEquipmentId = state => {
  const telehealthEq = state.service.equipment.find(e => e.name === 'Telehealth');
  return telehealthEq ? telehealthEq.id : null;
};

const getPCPEquipmentIds = state => state.service.equipment.filter(e => e.name.includes('PCP')).map(eq => eq.id);

const isTelehealth = state => state.service.therapistEquipmentIds.includes(getTelehealthEquipmentId(state));

const isPCP = state => {
  const pcpEquipments = getPCPEquipmentIds(state);
  return pcpEquipments && pcpEquipments.some(id => state.service.therapistEquipmentIds.includes(id));
};

const disableEquipmentSelection = state =>
  !!(state.requests.request.id && state.formFlow.currentView === 'edit' && (isTelehealth(state) || isPCP(state)));

const disableServiceSelection = state =>
  !!(state.requests.request.id && state.formFlow.currentView === 'edit' && (isTelehealth(state) || isPCP(state)));

const agencyTransportReasons = state =>
  state.service.agencyTransportReasons.filter(t => !t.inactive).length
    ? state.service.agencyTransportReasons
    : window.ambulnzConfig.transportReasons;

const disableEquipments = (dictionary, equipmentIds) =>
  !Object.values(dictionary).every(value => equipmentIds.some(id => value.includes(id)));

const equipmentCandidatesForDisabled = state => {
  const telehealthId = getTelehealthEquipmentId(state);
  const pcpIds = getPCPEquipmentIds(state);
  const equipmentIds = [...pcpIds, telehealthId].filter(Boolean);
  const shouldDisable = disableEquipments(state.service.transportReasonsAndEquipmentIdsDictionary, equipmentIds);

  return shouldDisable ? equipmentIds : [];
};

const title = state => {
  if (!state.service.transportReasons && state.service.callTypeId) {
    const transportReason = agencyTransportReasons(state).find(v => v.id === state.service.callTypeId);
    return transportReason ? transportReason.display_name : 'Requested Service';
  }

  if (state.requests.request.transportReasons && state.requests.request.transportReasons.length) {
    return state.requests.request.transportReasons
      .map(e => e.display_name)
      .filter(Boolean)
      .join(', ');
  }

  if (state.service.transportReasons && state.service.transportReasons.length > 0) {
    const trs = state.service.transportReasons
      .map(tr => {
        const transportReason = agencyTransportReasons(state).find(s => s.id === tr.transportReasonId);

        return (transportReason && transportReason.display_name) || '';
      })
      .filter(Boolean);

    return trs ? trs.join(', ') : 'Requested Service';
  }
  return 'Requested Service';
};
const MedicalServiceContainer = props => {
  const [etaState, setEtaState] = useState({
    ETADialogOpen: false,
    newEtaCallback: null,
  });
  const [previousState, setPreviousState] = useState(null);
  const [services, setServices] = useState([]);
  const [validateMedicalService, setValidateMedicalService] = useState(false);

  const updateServices = newServices => {
    setServices(newServices);
  };

  const customCloseNewETADialog = okPressed => {
    if (okPressed) {
      if (etaState.newEtaCallback) {
        etaState.newEtaCallback();
        props.closeNewETADialog(true);
      }
    } else {
      if (previousState) {
        props.setTransportReasons({
          transportReasons: previousState.transportReasons,
          isToAdd: previousState.checked,
          isNonTransport: props.isNonTransport,
          mode: props.formFlowMode,
          isRequested: props.isRequested,
        });
        previousState.therapistEquipmentIds.forEach(equipmentId => {
          applyEquipmentChange(equipmentId, previousState.checked)();
        });
        updateServices(previousState.services);
      }
    }
    setEtaState({
      ETADialogOpen: false,
      newEtaCallback: null,
    });
  };

  const handleChange = (services, selectedValue, checked) => {
    if (props.changeRequiresNewETA) {
      props.setPendingRequestUpdate(true);

      setPreviousState({
        transportReasons: props.transportReasons,
        therapistEquipmentIds: props.therapistEquipmentIds,
        services: services,
        selectedValue: selectedValue,
        checked: checked,
      });

      setEtaState({
        ETADialogOpen: true,
        newEtaCallback: applyServiceChange(services, selectedValue, checked),
      });

      return;
    } else {
      applyServiceChange(services, selectedValue, checked)();
    }
  };

  const applyServiceChange = (services, selectedValue, checked) => () => {
    props.setSaveErrorMessage({ selectedMedicalService: null });
    props.mobilityChange(props.mobilities.walk);
    props.setTransportReasons({
      transportReasons: services,
      selectedValue,
      isToAdd: checked,
      isNonTransport: props.isNonTransport,
      mode: props.formFlowMode,
      isRequested: props.isRequested,
    });
    props.setManualDropoffTimeSecs('1');
    props.setNonTransport(true);

    // for validating the form
    props.setAnswersForEmergency();
    props.setQuestionMode(false);
    setValidateMedicalService(true);

    if (services.length) {
      props.setManualPickupTimeSecs(services.reduce((acc, e) => acc + e.pickupTimeInSec, 0).toString());
    }
  };

  const applyEquipmentChange = (equipmentId, checked) => () => {
    props.equipmentChange({ equipmentId, checked, isNonTransport: props.isNonTransport });
  };

  const isRequiredEquipmentBeingRemoved = (equipmentId, checked) => {
    if (checked) {
      return false;
    }

    const { transportReasonsAndEquipmentIdsDictionary } = props;

    return (
      Object.keys(transportReasonsAndEquipmentIdsDictionary).length > 0 &&
      Object.values(transportReasonsAndEquipmentIdsDictionary).flat().includes(equipmentId)
    );
  };

  const handleEquipmentChange = (equipmentId, checked) => {
    if (props.changeRequiresNewETA && isRequiredEquipmentBeingRemoved(equipmentId, checked)) {
      props.setPendingRequestUpdate(true);
      return;
    }

    if (props.changeRequiresNewETA) {
      props.setPendingRequestUpdate(true);
      setEtaState({
        ETADialogOpen: true,
        newEtaCallback: applyEquipmentChange(equipmentId, checked),
      });
      return;
    }

    applyEquipmentChange(equipmentId, checked)();
  };

  return (
    props.display && (
      <MedicalService
        {...props}
        services={services}
        handleChange={handleChange}
        handleEquipmentChange={handleEquipmentChange}
        ETADialogOpen={etaState.ETADialogOpen}
        customCloseNewETADialog={customCloseNewETADialog}
        validateMedicalService={validateMedicalService}
      />
    )
  );
};

function mapStateToProps(state) {
  return {
    ETADialogBody: state.formFlow.ETADialogBody,
    ETADialogTitle: state.formFlow.ETADialogTitle,
    callTypeId: state.service.callTypeId,
    candidatesForDisabledEquipments: equipmentCandidatesForDisabled(state),
    changeRequiresNewETA: state.formFlow.changeRequiresNewETA,
    display:
      window.ambulnzConfig.userSettings.isMobileHealthServiceRequesterEnabled ||
      window.ambulnzConfig.constants.MOBILE_HEALTH_SERVICE_ENABLED,
    equipment: state.service.equipment,
    equipmentSelectDisabled: disableEquipmentSelection(state),
    formFlowMode: state.formFlow.currentView,
    isNonTransport: state.requests.isNonTransport,
    isRequested: !!state.requests.request.id,
    medicalServiceList: agencyTransportReasons(state),
    mobilities: state.service.mobilities,
    pcpEquipmentIds: getPCPEquipmentIds(state),
    serviceSelectDisabled: disableServiceSelection(state),
    shouldCollapse: transportReasonCollapse(state),
    telehealthEquipmentId: getTelehealthEquipmentId(state),
    therapistEquipmentIds: state.service.therapistEquipmentIds,
    title: title(state),
    transportReasons: state.service.transportReasons,
    transportReasonsAndEquipmentIdsDictionary: state.service.transportReasonsAndEquipmentIdsDictionary,
    triggerValidation: state.formFlow.triggerValidation,
  };
}

MedicalServiceContainer.prototype = {
  display: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, {
  ...requestsActions,
  ...serviceActions,
  ...locationActions,
  ...infoboxActions,
  ...formFlowActions,
})(MedicalServiceContainer);
