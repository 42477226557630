import withValidations from '../../common/withValidations';
import styledFrame from './ChiefComplaint.frame';

const validations = {
  chiefComplaintId: (value, props) => {
    if (props.agencyRequiresChiefComplaint && value === null) {
      return 'Select Chief Complaint';
    }

    return null;
  },
  chiefComplaintName: (value, props) => {
    const other = props.chiefComplaintId === 0;
    if (props.agencyRequiresChiefComplaint && other && !value) {
      return 'Must specify Chief Complaint description';
    }
    if (value && value.length > 255) {
      return 'Cannot exceed 255 characters';
    }
    return null;
  },
};

export default withValidations(validations)(styledFrame);
