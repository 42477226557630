import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { newETArequired } from '../../../messages';
import AmbCheckbox from 'components/common/AmbCheckbox';
import AmbTextField from 'components/common/AmbTextField';

export default function CareProvidedAtReceivingFacilityFrame({
  show,
  checked,
  handleChange,
  disabled,
  careProvidedAtDescChange,
  description,
  errors,
  dialogOpen,
  handleDialogClose,
}) {
  const labelStyle = {
    fontSize: 15,
    color: '#4F636B',
    top: 0,
    left: 0,
    position: 'absolute',
  };

  const containerStyle = {
    marginTop: 10,
    marginLeft: 10,
  };

  const textareaStyle = {
    paddingTop: 20,
  };

  return (
    <>
      {show && (
        <AmbCheckbox
          checked={checked}
          inputId="higherCareProvided"
          label={<>Patient is receiving care not available at the sending facility</>}
          propsStyles={{ display: 'inline-flex' }}
          disabled={disabled || null}
          handleChange={handleChange}
        />
      )}
      {checked && (
        <AmbTextField
          data-testid="careProvidedAtReceivingFacilityDescription"
          value={description}
          onChange={({ target }) => careProvidedAtDescChange(target.value)}
          disabled={disabled || null}
          label="Care Provided at Receiving Facility"
          id="higherCareDescription"
          required
          error={!!errors.description}
          subLabel={errors.description}
          InputLabelProps={{
            style: labelStyle,
            shrink: true,
          }}
          InputProps={{
            style: textareaStyle,
          }}
          multiline
          rows="2"
          fullWidth
          wrapperStyle={containerStyle}
        />
      )}
      <ConfirmationDialog
        open={dialogOpen}
        fullWidth
        maxWidth="360px"
        onClose={handleDialogClose}
        title={newETArequired.title}
        cancel
      >
        <div>{newETArequired.body}</div>
      </ConfirmationDialog>
    </>
  );
}

CareProvidedAtReceivingFacilityFrame.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};
