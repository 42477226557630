import React from 'react';

export default function (props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
      style={{ ...props.style, cursor: 'pointer' }}
    >
      <g id="Flag">
        <path
          id="Vector"
          d="M4 17.1667V3.16666H11L11.5 5.16666H16V13.1667H11L10.5 11.1667H5.5V17.1667H4Z"
          fill="#F2404B"
        />
      </g>
    </svg>
  );
}
