import React from 'react';
import PropTypes from 'prop-types';
import Eligibility from '../../Eligibility';
import BillerVerified from './BillerVerified';
import Coverage from './Coverage';
import PcrVerified from './PcrVerified';

class PatientCoverageFrame extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.facesheetAttached !== this.props.facesheetAttached) {
      this.props.validateAll(nextProps);
    }

    if (nextProps.coverageRequired !== this.props.coverageRequired) {
      this.props.validateAll(nextProps);
    }

    if (nextProps.shouldValidateAll !== this.props.shouldValidateAll && nextProps.shouldValidateAll) {
      this.props.validateAll(nextProps);
    }
    if (nextProps.triggerValidation !== this.props.triggerValidation && nextProps.triggerValidation) {
      this.props.validateAll(nextProps);
    }

    if (
      nextProps.triggerPatientValidation !== this.props.triggerPatientValidation &&
      nextProps.triggerPatientValidation
    ) {
      this.props.validateAll(nextProps);
    }

    this.props.setSaveErrorMessage(nextProps.errors);
  }

  render() {
    const { handleErrors } = this.props;

    const borderColor =
      this.props.isBillerVerified && this.props.isPcrVerified
        ? {
            borderTop: '#33cc99 2px solid',
            borderRight: '#33cc99 2px solid',
            borderLeft: '#33cc99 2px solid',
            padding: '0 10px',
          }
        : {
            borderTop: '2px solid #ff5252',
            borderRight: '2px solid #ff5252',
            borderLeft: '2px solid #ff5252',
            padding: '0 10px',
          };

    const borderBottomColor = this.props.isBillerVerified
      ? {
          borderBottom: '#33cc99 2px solid',
        }
      : {
          borderBottom: '2px solid #ff5252',
        };

    const containerStyle = {
      position: 'relative',
      margin: '30px -10px -10px -10px',
      padding: '0 12px',
    };

    const getContainerStyle = () => {
      if (this.props.loadedRequest && this.props.coverageRequired) {
        return {
          ...containerStyle,
          ...borderColor,
          ...(!this.props.isPcrVerificationAvailable && borderBottomColor),
        };
      }
      return containerStyle;
    };

    return (
      <div style={getContainerStyle()}>
        <Eligibility />
        {this.props.coordinationTypes.map((type, i) => (
          <Coverage
            key={type.id}
            inputId={type.name}
            shouldValidateAll={this.props.shouldValidateAll}
            disabled={this.props.disabled || null}
            handleErrors={handleErrors}
            insuranceName={type.displayName}
            coordinationTypeId={type.id}
            swapWithCoordinationType={this.props.coordinationTypes[i + 1]}
            isRequired={i === 0}
            isLineHidden={i === 0}
            first={i === 0}
            reorder={i !== this.props.coordinationTypes.length - 1}
            coverageRequired={this.props.coverageRequired}
          />
        ))}

        {this.props.user.isDispatcher && (
          <>
            <BillerVerified />
            {this.props.isPcrVerificationAvailable && (
              <PcrVerified isBillerVerified={this.props.isBillerVerified} isPcrVerified={this.props.isPcrVerified} />
            )}
          </>
        )}
      </div>
    );
  }
}

PatientCoverageFrame.propTypes = {
  errors: PropTypes.shape().isRequired,
  handleErrors: PropTypes.func.isRequired,
  isBillerVerified: PropTypes.bool.isRequired,
  loadedRequest: PropTypes.bool.isRequired,
  shouldValidateAll: PropTypes.bool.isRequired,
  triggerValidation: PropTypes.bool.isRequired,
  triggerPatientValidation: PropTypes.bool.isRequired,
  user: PropTypes.shape().isRequired,
  validateAll: PropTypes.func.isRequired,
  facesheetAttached: PropTypes.bool.isRequired,
  coverageRequired: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default PatientCoverageFrame;
