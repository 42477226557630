import createMiddleware from '../helper';
import * as infoboxActions from '../../actions/infobox.actions';
import * as requestsActions from '../../actions/requests.actions';
import * as formFlowActions from '../../actions/formFlow.actions';

const setIsPcrVerified = createMiddleware(infoboxActions.pcrVerifiedChange, ({ payload, getState, dispatch }) => {
  if (getState().requests.request.id && !getState().formFlow.loadRequestInProgress) {
    dispatch(
      requestsActions.setIsPcrVerified({
        isPcrVerified: payload,
        pcrVerifiedBy: getState().user.model.fullName,
      }),
    );
    dispatch(infoboxActions.asyncAutoSavePcrVerified());
    dispatch(formFlowActions.autoSaveInProgress(true));
  }
});

const middlewares = [setIsPcrVerified];

export default middlewares;
