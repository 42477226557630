import React from 'react';
import { connect } from 'react-redux';
import StyledFrame from './PcrVerified.frame';
import moment from 'moment';
import * as infoboxActions from '../../../../redux/actions/infobox.actions';

const PcrVerifiedContainer = props => <StyledFrame {...props} />;

function mapStateToProps(state) {
  return {
    pcrVerifiedAt: moment(state.requests.pcrVerifiedAt).format('MM/DD'),
    pcrVerifiedBy: state.requests.pcrVerifiedBy,
    loadedRequest: state.formFlow.loadedRequest,
    disabled: !state.requests.request.isCompleted,
  };
}

export default connect(mapStateToProps, {
  ...infoboxActions,
})(PcrVerifiedContainer);
