import { createReducer } from 'redux-act';
import * as action from '../actions/requests.actions';
import * as chiefComplaintActions from '../actions/chiefComplaint.actions';
import * as willCallActions from '../actions/willCall.actions';
import * as locationComponentActions from '../../components/PickupDestination/redux/actions/component.actions';
import {
  careProvidedAtChbChange,
  careProvidedAtDescChange,
} from '../../components/ServiceLevel/CareProvidedAtReceivingFacility/actions';
import requestSchema from '../../schemas/request.schema';
import { parseChiefComplaint, parseRequestVerifications } from '../../parsers/request.parser';

function setAttribute(request, attrName, value) {
  const updatedRequest = { ...request };
  updatedRequest[attrName] = value;
  return updatedRequest;
}

export const initialState = {
  list: [],
  request: requestSchema.parse({}),
  billerVerifiedBy: null,
  isBillerVerified: false,
  billerVerifiedAt: null,
  pcrVerifiedBy: null,
  isPcrVerified: false,
  pcrVerifiedAt: null,
  shareLink: null,
  willCall: false,
  loadedWillCall: false,
  triggeredBy: null,
  isJustActivated: false,
  chiefComplaintName: '',
  chiefComplaintId: null,
  chiefComplaintType: null,
  chiefComplaintList: [],
  estimatedWaitForReturnMins: '',
  isRoundTrip: false,
  canRequestBeActivatedByPatient: false,
  crewMustWaitForPatient: false,
  pcrApproved: false,
  pickupDepartmentId: '',
  destinationDepartmentId: '',
  careProvidedAtReceivingFacility: false,
  careProvidedAtReceivingFacilityDescription: '',
  canReturnRequestBeActivatedByPatient: false,
  charges: null,
  isNonTransport: false,
  requestPcrVerification: null,
};

const requestsReducer = createReducer(
  {
    [action.getRequestsSuccess]: (state, payload) => ({
      ...state,
      list: payload.map(r => requestSchema.parse(r)),
    }),
    [action.getRequestSuccess]: (state, payload) => ({
      ...state,
      request: requestSchema.parse(payload),
    }),
    [action.editRequestSuccess]: (state, payload) => ({
      ...state,
      request: requestSchema.parse(payload),
      requestType: payload.requestType,
      isBillerVerified: payload.isBillerVerified,
      billerVerifiedAt: payload.billerVerifiedAt,
      billerVerifiedBy:
        payload.billerVerifiedById && `${payload.billerVerifiedBy.firstName} ${payload.billerVerifiedBy.lastName}`,
      ...parseChiefComplaint(payload),
      careProvidedAtReceivingFacility: payload.careProvidedAtReceivingFacility,
      careProvidedAtReceivingFacilityDescription: payload.careProvidedAtReceivingFacilityDescription || '',
    }),
    [action.fetchRequestVerificationsSuccess]: (state, payload) => {
      const { pcr } = parseRequestVerifications(payload);
      return {
        ...state,
        requestPcrVerification: pcr,
        isPcrVerified: pcr.isVerified,
        pcrVerifiedAt: pcr.createdAt,
        pcrVerifiedBy: pcr.isVerified && pcr.createdBy,
      };
    },
    [action.createRequestSuccess]: (state, payload) => ({
      ...state,
      request: requestSchema.parse(payload),
    }),
    [action.updateRequestSuccess]: (state, payload) => ({
      ...state,
      request: requestSchema.parse(payload),
    }),
    [action.createShareLinkSuccess]: (state, payload) => ({
      ...state,
      shareLink: payload.accessToken,
    }),
    [action.setTriggeredBy]: (state, payload) => ({
      ...state,
      triggeredBy: payload,
    }),
    [action.lightsAndSirensChange]: (state, payload) => ({
      ...state,
      request: setAttribute(state.request, 'isLightsAndSirens', payload),
    }),
    [action.setLightsAndSirens]: (state, payload) => ({
      ...state,
      request: setAttribute(state.request, 'isLightsAndSirens', payload),
    }),
    [action.roundTripChange]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [action.asyncRoundTripChange]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [action.setIsBillerVerified]: (state, payload) => ({
      ...state,
      isBillerVerified: payload.isBillerVerified,
      billerVerifiedAt: payload.isBillerVerified ? new Date().toISOString() : '',
      billerVerifiedBy: payload.isBillerVerified ? payload.billerVerifiedBy : null,
    }),
    [action.setIsPcrVerified]: (state, payload) => ({
      ...state,
      isPcrVerified: payload.isPcrVerified,
      pcrVerifiedAt: payload.isPcrVerified ? new Date().toISOString() : '',
      pcrVerifiedBy: payload.isPcrVerified ? payload.pcrVerifiedBy : null,
    }),
    [willCallActions.setWillCall]: (state, payload) => ({
      ...state,
      willCall: payload,
      loadedWillCall: false,
    }),
    [willCallActions.setLoadedWillCall]: (state, payload) => ({
      ...state,
      loadedWillCall: payload,
      willCall: payload,
    }),
    [willCallActions.unReserveSetWillCall]: state => ({
      ...state,
      loadedWillCall: false,
      willCall: true,
    }),
    [willCallActions.setIsJustActivated]: (state, payload) => ({
      ...state,
      isJustActivated: payload,
    }),
    [chiefComplaintActions.setChiefComplaint]: (state, payload) => ({
      ...state,
      chiefComplaintId: payload.id,
      chiefComplaintName: payload.name,
      chiefComplaintType: payload.type,
    }),
    [chiefComplaintActions.clearChiefComplaint]: state => ({
      ...state,
      chiefComplaintId: null,
      chiefComplaintName: null,
      chiefComplaintType: null,
    }),
    [locationComponentActions.getPcrStatusSuccess]: (state, payload) => ({
      ...state,
      pcrApproved: !!payload.isBilledOrApproved,
    }),
    [action.setStatus]: (state, payload) => ({
      ...state,
      request: requestSchema.parse({
        ...state.request,
        status: payload.status,
      }),
    }),
    [locationComponentActions.selectPickupDepartment]: (state, payload) => ({
      ...state,
      pickupDepartmentId: payload || null,
    }),
    [locationComponentActions.clearPickupDepartment]: state => ({
      ...state,
      pickupDepartmentId: null,
    }),
    [locationComponentActions.selectDestinationDepartment]: (state, payload) => ({
      ...state,
      destinationDepartmentId: payload || null,
    }),
    [locationComponentActions.clearDestinationDepartment]: state => ({
      ...state,
      destinationDepartmentId: null,
    }),
    [careProvidedAtChbChange]: (state, payload) => ({
      ...state,
      careProvidedAtReceivingFacility: payload,
      careProvidedAtReceivingFacilityDescription: payload ? state.careProvidedAtReceivingFacilityDescription : '',
    }),
    [careProvidedAtDescChange]: (state, payload) => ({
      ...state,
      careProvidedAtReceivingFacilityDescription: payload,
    }),
    [action.setProvider]: (state, payload) => ({
      ...state,
      request: requestSchema.parse({
        ...state.request,
        providerSystemId: payload,
      }),
    }),
    [action.setWillCallReturn]: (state, payload) => ({
      ...state,
      request: requestSchema.parse({
        ...state.request,
        isWillCallReturn: payload,
      }),
    }),
    [action.setReturnRequestCanBeActivatedByPatient]: (state, payload) => ({
      ...state,
      canReturnRequestBeActivatedByPatient: payload,
    }),
    [action.setRequestCanBeActivatedByPatient]: (state, payload) => ({
      ...state,
      request: requestSchema.parse({
        ...state.request,
        canRequestBeActivatedByPatient: payload,
      }),
    }),
    [action.getRequestChargesSuccess]: (state, payload) => ({
      ...state,
      charges: payload,
    }),
    [action.asyncSetRequestCanBeActivatedByPatient]: (state, payload) => ({
      ...state,
      request: requestSchema.parse({
        ...state.request,
        canRequestBeActivatedByPatient: payload,
      }),
    }),
    [action.setNonTransport]: (state, payload) => ({
      ...state,
      isNonTransport: payload,
    }),
    [willCallActions.resetWillCall]: state => ({
      ...state,
      willCall: false,
      loadedWillCall: false,
      isJustActivated: false,
      isRoundTrip: false,
    }),
  },
  initialState,
);

export default requestsReducer;
