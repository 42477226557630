import withValidations from '../../../common/withValidations';
import styledFrame from './PatientAdditionalInfo.frame';

const validations = {
  address1: (value, props) => {
    if (props.isNonTransport && !value) {
      return 'Patient must have an address';
    }
    if (value && value.length > 500) {
      return 'Cannot exceed 500 characters';
    }
    return null;
  },
  address2: (value) => {
    if (value && value.length > 500) {
      return 'Cannot exceed 500 characters';
    }
    return null;
  },
  city: (value, props) => {
    if (props.isNonTransport && !value) {
      return 'Patient must have a city';
    }
    if (value && value.length > 255) {
      return 'Cannot exceed 255 characters';
    }
    return null;
  },
  state: (value, props) => {
    if (props.isNonTransport && !value) {
      return 'Patient must have a state';
    }
    return null;
  },
  zip: (value, props) => {
    if (props.isNonTransport && !value) {
      return 'Patient must have a zip code';
    }
    if (value && value.trim().length < 5) {
      return 'Patient zip must be 5 digits';
    }
    return null;
  },
};

export default withValidations(validations)(styledFrame);
