import _ from 'lodash';
import { createReducer } from 'redux-act';
import * as action from '../actions/infobox.actions';
import { parseLoadedPayers, loadCoverages } from '../../parsers/payer.parser';
import parsePhone from '../../parsers/phone.parser';
import { payer } from '../../schemas/infobox.schema';
import coverageStateSchema from '../../schemas/coverageState.schema';
import { updateDynamicState } from './helpers/dynamicReducer.helper';

export const initialState = {
  coverages: {},
  patientFirstName: '',
  patientMiddleName: '',
  patientLastName: '',
  patientEmail: '',
  patientBirthday: null,
  patientSsn: '',
  patientMRN: '',
  patientWeight: '',
  patientHeight: '',
  patientHeightInches: '',
  comments: {},
  formValid: false,
  requesterFirstName: '',
  requesterLastName: '',
  requesterPhone: '',
  requesterExtension: '',
  notes: '',
  focusRequesterFirstName: false,
  eligibilityHTML: '',
  eligibilityLoading: false,
  isSubmitPatientInfoInProgress: false,
  requesterDirty: false,
  coveragesDirty: false,
  verificationTypes: null,
};

const updateCoverages = updateDynamicState('coverages', () => coverageStateSchema.parse({}));
const updateCoveragesWithoutRequired = (state, coordinationTypeId, updateObj) => {
  const newUpdateObj = { ...updateObj };
  delete newUpdateObj.isRequired;

  return updateCoverages(state, coordinationTypeId, newUpdateObj);
};
const setPayerWarnings = (coverages, payers) => {
  const coveragesWithWarnings = JSON.parse(JSON.stringify(coverages));

  Object.keys(coveragesWithWarnings).forEach(key => (coveragesWithWarnings[key].warnings = []));

  payers.forEach(p =>
    Object.keys(coverages).forEach(c => {
      const { payerId } = coverages[c].payer;
      if (payerId === p.payerId) {
        coveragesWithWarnings[c].warnings = p.warnings;
      }
    }),
  );

  return coveragesWithWarnings;
};

const last = state => state.slice(-1)[0];
const loadPatient = (patient, item) => (patient ? last(patient.demographics)[item] : '');
const loadRequester = (requester, item) => (requester ? last(requester.data)[item] : '');
const loadPatientHeight = payload => {
  const height = loadPatient(payload.patient, 'height');
  return height && height.split("'")[0];
};
const loadPatientHeightInches = payload => {
  const height = loadPatient(payload.patient, 'height');
  return height && height.split("'")[1].slice(0, -1);
};
const loadPatientMRN = payload => {
  const patientExternalIds = _.get(payload.patient, 'patientExternalId', []);
  const requesterAgencyGroupId = _.get(payload.requesterAgency, 'requesterAgencyGroup.id', null);

  const patientExternalId = patientExternalIds.find(
    externalId => externalId.requesterAgencyGroupId === requesterAgencyGroupId,
  );
  return patientExternalId && patientExternalId.externalId;
};

const infoboxReducer = createReducer(
  {
    [action.initCoverage]: (state, { coordinationTypeId, isRequired }) => ({
      ...state,
      ...updateCoverages(state, coordinationTypeId, {
        coordinationTypeId,
        isRequired,
      }),
    }),
    [action.setPayerInfo]: (state, { coordinationTypeId, ...payload }) => ({
      ...state,
      ...updateCoveragesWithoutRequired(state, coordinationTypeId, payload),
    }),
    [action.clearPayer]: (state, coordinationTypeId) => ({
      ...state,
      ...updateCoveragesWithoutRequired(state, coordinationTypeId, {
        payer: payer.parse({}),
        payerName: '',
        payerQuery: '',
        payerInput: '',
        policyNumber: '',
        authorizationNumber: '',
      }),
    }),
    [action.queryPayers]: (state, { coordinationTypeId, query }) => ({
      ...state,
      ...updateCoveragesWithoutRequired(state, coordinationTypeId, {
        payerInput: query,
      }),
    }),
    [action.setRequesterInfo]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [action.setPatientDetails]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [action.setRequesterPhone]: (state, payload) => ({
      ...state,
      requesterPhone: parsePhone(payload),
    }),
    [action.setNotes]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [action.setRequester]: (state, payload) => ({
      ...state,
      requesterFirstName: payload.firstName,
      requesterLastName: payload.lastName,
      requesterPhone: payload.phone,
      requesterExtension: payload.phoneExtension,
    }),
    [action.fetchPayersSuccess]: (state, { payers, coordinationTypeId }) => ({
      ...state,
      ...updateCoveragesWithoutRequired(state, coordinationTypeId, {
        payers: parseLoadedPayers(payers),
      }),
    }),
    [action.setBirthday]: (state, payload) => ({
      ...state,
      patientBirthday: payload,
    }),
    [action.setValid]: (state, payload) => ({
      ...state,
      formValid: payload,
    }),
    [action.loadRequestDetails]: (state, payload) => ({
      ...state,
      requesterFirstName: loadRequester(payload.requester, 'firstName'),
      requesterLastName: loadRequester(payload.requester, 'lastName'),
      requesterPhone: loadRequester(payload.requester, 'phone'),
      requesterExtension: loadRequester(payload.requester, 'extension'),
      patientFirstName: loadPatient(payload.patient, 'firstName'),
      patientMiddleName: loadPatient(payload.patient, 'middleName'),
      patientLastName: loadPatient(payload.patient, 'lastName'),
      patientEmail: loadPatient(payload.patient, 'email'),
      patientBirthday: loadPatient(payload.patient, 'dob') || null,
      patientSsn: loadPatient(payload.patient, 'ssn'),
      patientMRN: loadPatientMRN(payload),
      patientHeight: loadPatientHeight(payload),
      patientHeightInches: loadPatientHeightInches(payload),
      patientWeight: loadPatient(payload.patient, 'weight'),
    }),
    [action.loadCoverages]: (state, payload) => ({
      ...state,
      coverages: loadCoverages(payload),
      loadCoverageInProgress: false,
    }),
    [action.setLoadCoverageInProgress]: (state, payload) => ({
      ...state,
      loadCoverageInProgress: payload,
    }),
    [action.loadComments]: (state, payload) => ({
      ...state,
      notes: payload,
    }),
    [action.setFocusRequesterFirstName]: (state, payload) => ({
      ...state,
      focusRequesterFirstName: payload,
    }),
    [action.setSubmitPatientInfoInProgress]: (state, payload) => ({
      ...state,
      isSubmitPatientInfoInProgress: payload,
    }),
    [action.setRequesterDirty]: (state, payload) => ({
      ...state,
      requesterDirty: payload,
    }),
    [action.setCoveragesDirty]: (state, payload) => ({
      ...state,
      coveragesDirty: payload,
    }),
    [action.checkPayerRulesResponse]: (state, payload) => ({
      ...state,
      coverages: setPayerWarnings(state.coverages, payload),
    }),
    [action.resetInfobox]: () => ({
      ...initialState,
    }),
    [action.setVerificationTypes]: (state, payload) => ({
      ...state,
      verificationTypes: payload,
    }),
  },
  initialState,
);

export default infoboxReducer;
