import ApiService from './api.service';
import { requestsFilter, requestFilter } from './filters/request.filters';
import PatientService from './patient.service';

export default {
  baseUrl: () => window.ambulnzConfig.tenantedRestApiUrl,

  async createRequest(payload) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests`;
    let response = await ApiService.post(url, payload);
    response = response.json();
    return response;
  },

  async updateRequest(requestId, payload) {
    payload.isWeb = true;
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}`;
    let response = await ApiService.put(url, payload);
    response = response.json();
    return response;
  },

  async updateRequestTelehealthLink(requestId, payload) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/TelehealthLink`;

    let response = await ApiService.post(url, payload);

    return await response.json();
  },

  async updateBillerVerified(requestId, payload) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/billerVerified`;
    let response = await ApiService.patch(url, payload);
    response = response.json();
    return response;
  },

  async getRequestVerifications(requestId) {
    const url = `${window.ambulnzConfig.tenantedRestApiV2Url}/Requests/${requestId}/RequestVerifications`;
    let response = await ApiService.get(url);
    response = response.json();
    return response;
  },

  async postPcrVerified(requestId, payload) {
    const url = `${window.ambulnzConfig.tenantedRestApiV2Url}/Requests/${requestId}/RequestVerifications`;
    let response = await ApiService.post(url, payload);
    response = response.json();
    return response;
  },

  async deletePcrVerified(requestId, verificationId) {
    const url = `${window.ambulnzConfig.tenantedRestApiV2Url}/Requests/${requestId}/RequestVerifications/${verificationId}`;
    let response = await ApiService.delete(url);
    response = response.json();
    return response;
  },

  async getRequest(requestId) {
    const filter = requestFilter();
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}?filter=${JSON.stringify(filter)}`;
    let response = await ApiService.get(url);
    response = await response.json();

    return response;
  },

  async getSimilarRequests(requestId) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/getSimilar`;
    let response = await ApiService.get(url);
    response = response.json();
    return response;
  },

  async getRequests(userId) {
    const filter = requestsFilter(userId);
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests?filter=${JSON.stringify(filter)}`;
    let response = await ApiService.get(url);
    response = response.json();

    return response;
  },

  async fetchWithRetry(url, retries = 5, delay = 5000) {
    try {
      const response = await ApiService.get(url);
      if (response.status === 429 && retries > 0) {
        await new Promise(resolve => setTimeout(resolve, delay));
        return this.fetchWithRetry(url, retries - 1, delay);
      }
      return response;
    } catch (error) {
      if (retries > 0) {
        await new Promise(resolve => setTimeout(resolve, delay));
        return this.fetchWithRetry(url, retries - 1, delay);
      }
      throw error;
    }
  },

  async getProviderEtas(requestId, { targetTime, etaOptions, serviceByDate }) {
    const url = `${
      window.ambulnzConfig.tenantedRestApiUrl
    }/v1/providers/eta?requestId=${requestId}&targetTime=${targetTime}&etaOptions=${JSON.stringify(
      etaOptions,
    )}&serviceByDate=${serviceByDate}`;

    let response;
    try {
      response = await this.fetchWithRetry(url);
      response = await response.json();
    } catch (error) {
      response = {
        error: true,
        message: 'Server Error',
        name: 'Server Error',
        status: 502,
      };
    }

    return response;
  },

  async getRequestedArrivalTime(requestId, state) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/arrivalTime?requestedAppointmentTime=${
      state.eta.requestedAppointmentTime
    }&estimatedTimeOnScene=${+state.location.manualPickupSecs}&pickupPlace=${JSON.stringify(
      state.map.fromWaypoint,
    )}&destinationPlace=${JSON.stringify(state.map.toWaypoint)}`;
    let response = await ApiService.get(url);
    response = response.json();
    return response;
  },

  async getLinearDistance(fromWaypoint, toWaypoint) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/linearDistance?pickupGps=${JSON.stringify(
      fromWaypoint,
    )}&destinationGps=${JSON.stringify(toWaypoint)}`;
    const response = await ApiService.get(url);
    return response.json();
  },

  async getSchedule(requestId) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/schedule`;
    const response = await ApiService.get(url, {}, { noCache: true });
    return response.json();
  },

  async updateSchedule(requestId, payload) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/schedule`;
    let response = await ApiService.put(url, payload);
    response = response.json();
    return response;
  },

  async acceptEta(requestId, payload) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/acceptEta`;
    let response = await ApiService.patch(url, payload);
    response = response.json();
    return response;
  },

  async rejectEtas(requestId, rejectedEtas) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/rejectEta`;
    return Promise.all(
      rejectedEtas.map(oe =>
        ApiService.patch(url, {
          planId: oe.candidatePlanId,
          unitId: oe.unitId,
          pickupTime: oe.pickupTime,
        }),
      ),
    );
  },

  async deleteSchedule(requestId) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/schedule`;
    let response = await ApiService.delete(url);
    response = response.json();
    return response;
  },

  async unReserve(requestId, status) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/unAssignRequest`;
    let response = await ApiService.put(url, { status });
    response = response.json();
    return response;
  },

  async createNotReserved(requestId, payload) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/notReserved`;
    let response = await ApiService.post(url, payload);
    response = response.json();
    return response;
  },

  async submit(requestId, payload, requesterAgencyGroup) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/submit`;

    try {
      let response = await ApiService.put(url, payload);
      response = await response.json();

      if (response.patientId) {
        await PatientService.updatePatientMrn(response.patientId, payload.patient.mrn, requesterAgencyGroup);
      }

      return response;
    } catch (error) {
      return {
        error: {
          name: `Failed to update patient information`,
          message: 'Please contact support',
          status: 500,
        },
      };
    }
  },

  async cancelRequest(requestId, cancelReasonTypeId, isCancelConfirmedByRequester = true) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/cancel`;
    const payload = { cancelReasonTypeId, isCancelConfirmedByRequester };
    let response = await ApiService.put(url, payload);
    response = response.json();
    return response;
  },

  async createShareLink(requestId, address) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/RequestShares`;
    let response = await ApiService.post(url, { requestId, address });
    response = response.json();
    return response;
  },

  async getShareLink(requestId) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/RequestShares/findOne?filter={"where": {"requestId": ${requestId}}}`;
    const response = await ApiService.get(url);
    return response.json();
  },

  async duplicateRequest(requestId) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/duplicate`;
    const response = await ApiService.post(url);

    return response.json();
  },
};
