import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import AmbInputLabel from 'components/common/AmbInputLabel';
import { theme } from '../../../theme';

const StyledTextArea = styled.textarea`
  font: inherit;
  color: currentColor;
  border: 0;
  margin: 0;
  padding: 0;
  resize: vertical;
  display: block;
  min-width: 0;
  outline: none;
  box-sizing: content-box;
  background: none;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
`;

const Wrapper = styled.div`
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  position: relative;
  font-size: 14px;
  padding: 6px 0 7px;
  width: 100%;

  &::before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: '';
    position: absolute;
    transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.42);
  }

  &::after {
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    content: '';
    position: absolute;
    transform: ${props => (props.isFocused ? 'scaleX(1)' : 'scaleX(0)')};
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: #004053;
  }
`;

const TextArea = ({ id, name, fullWidth, onChange }) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <>
      <Wrapper isFocused={isFocused}>
        <div style={{ position: 'relative', width: '100%' }}>
          <StyledTextArea
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            type="text"
            rows="5"
            rowsMax="7"
            maxLength="100"
            required
            onChange={onChange}
            name={name}
            id={id}
            style={{ width: fullWidth ? '100%' : 'auto' }}
          />
        </div>
      </Wrapper>
    </>
  );
};

const FlagReasonDialogFrame = props => {
  const [reason, setReason] = useState({});
  const [disableConfirm, setDisableConfirm] = useState(true);

  useEffect(() => {
    if (props.flagReasonDialog.open) {
      setReason({});
      setDisableConfirm(true);
    }
  }, [props.flagReasonDialog.open]);

  const handleInputChange = event => {
    setReason({ [props.flagReasonDialog.locationType]: event.target.value });
    setDisableConfirm(!event.target.value);
  };

  const labelProps = {
    style: { ...theme.mixins.inputLabel },
    shrink: true,
  };

  return (
    <ConfirmationDialog
      open={props.flagReasonDialog.open}
      onClose={ok => props.handleClose(ok, reason)}
      title={props.reasonRequiredForFlaggingLocationTitle}
      fullWidth
      maxWidth="360px"
      cancel
      okText="Save"
      okButtonStyle={
        disableConfirm
          ? { backgroundColor: theme.palette.grey.light, color: '#94999B' }
          : { backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.contrastText }
      }
      disableConfirm={disableConfirm}
    >
      <div>{props.reasonRequiredForFlaggingLocationBody}</div>
      <div
        style={{
          margin: 0,
          marginTop: 15,
          width: '100%',
          border: 0,
          display: 'inline-flex',
          padding: 0,
          position: 'relative',
          minWidth: 0,
          flexDirection: 'column',
        }}
      >
        <AmbInputLabel htmlFor="comments" style={labelProps.style} shrink required>
          Reason
        </AmbInputLabel>
        <TextArea name="comments" id="comments" fullWidth onChange={handleInputChange} />
      </div>
    </ConfirmationDialog>
  );
};

export default FlagReasonDialogFrame;
