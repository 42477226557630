import * as infoboxActions from '../actions/infobox.actions';
import * as patientActions from '../actions/patient.actions';
import * as formFlowActions from '../actions/formFlow.actions';
import { loadCoverages } from '../../parsers/payer.parser';
import { formatDateWithoutTimezone } from '../../helpers/dateFormatter';
import { parseLastSearch } from '../epics/helpers/patient.helper';
import {
  populateCoverageFromPatientSearch,
  userConfiguredForExternalPatientSearch,
} from '../../flags/patientSearch.flag';
import { getNullCoverageFields } from '../../parsers/infobox.parser';
import { mrnSearchEnabled } from '../../flags/display.flag';
import { parseInfoBox } from '../../parsers/infobox.parser';

const selectPatientMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (patientActions.selectPatient.match(action)) {
      const { selectedPatient } = getState().patient;
      if (!selectedPatient) {
        return;
      }

      const [patient] = selectedPatient.demographics;

      if (selectedPatient.coverages) {
        selectedPatient.coverages.map(coverage => ({ ...coverage, ...getNullCoverageFields() }));
      }
      const coverages = loadCoverages(selectedPatient);

      dispatch(
        infoboxActions.setPatientDetails({
          patientFirstName: patient.firstName,
          patientMiddleName: patient.middleName || '',
          patientLastName: patient.lastName,
          patientEmail: patient.email,
          patientSsn: patient.ssn,
          patientMRN: selectedPatient.externalId,
        }),
      );

      dispatch(
        patientActions.setPatientInfo({
          address1: patient.address1,
          address2: patient.address2,
          city: patient.city,
          state: patient.state,
          zip: patient.zip,
          phone: patient.phone,
        }),
      );

      if (populateCoverageFromPatientSearch(getState())) {
        selectedPatient.coverages.forEach(coverage => {
          const stateCoverage =
            {
              ...coverages[coverage.coordinationTypeId],
              ...getNullCoverageFields(),
            } || {};
          if (coverage) {
            dispatch(
              infoboxActions.setPayerInfo({
                coordinationTypeId: coverage.coordinationTypeId,
                ...stateCoverage,
              }),
            );
            dispatch(infoboxActions.billerVerifiedChange(false));
            dispatch(infoboxActions.pcrVerifiedChange(false));
          }
        });
      }

      dispatch(patientActions.setPhone(patient.phone));
      dispatch(infoboxActions.setBirthday(formatDateWithoutTimezone(patient.dob)));
      dispatch(patientActions.autoSavePatientDetails());
      dispatch(patientActions.setDirty(false));
      dispatch(patientActions.controlPatientModal(false));
      dispatch(formFlowActions.setTriggerPatientValidation(false));
      dispatch(formFlowActions.setTriggerPatientValidation(true));
    }
  };

const updatePatientsList =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (patientActions.updatePatientsList.match(action)) {
      const state = getState();
      const { patients } = state.patient;
      const { infobox } = state;

      // If we do not need to show selected patient if it's the only result from the search
      if (patients.length === 1) {
        const patientDemographics = patients[0].demographics[0];
        if (
          infobox.patientSsn === patientDemographics.ssn &&
          infobox.patientFirstName === patientDemographics.firstName &&
          infobox.patientLastName === patientDemographics.lastName &&
          infobox.patientBirthday === patientDemographics.dob &&
          infobox.patientEmail === patientDemographics.email
        ) {
          return dispatch(patientActions.controlPatientModal(false));
        }
      }

      dispatch(patientActions.controlPatientModal(true));
    }
  };

const startNewSearchMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (!patientActions.searchPatient.match(action)) {
      return null;
    }

    if (!window.ambulnzConfig.constants.PATIENT_SEARCH_ACTIVE) {
      return null;
    }

    const state = getState();
    const { patient, user } = state;

    const { selectedPatient, dirty } = patient;
    if (!dirty && selectedPatient) return null;

    if (patient.lastSearch === parseLastSearch(state)) return null;

    // so if mrn search enabled we should not search if params are not valid

    const patientDetails = parseInfoBox(state).patient;
    const demographicsExist =
      patientDetails.firstName || patientDetails.lastName || patientDetails.dob || patientDetails.email;

    if (mrnSearchEnabled(state) && !demographicsExist && !state.infobox.patientMRN) {
      return null;
    }

    if (!mrnSearchEnabled(state) && !patientDetails.ssn && !demographicsExist) {
      return null;
    }

    if (user.model.isDispatcher || userConfiguredForExternalPatientSearch(state)) {
      dispatch(patientActions.setSearchInProgress(true));
      dispatch(patientActions.asyncSearchPatient());
    }
  };

export const patientSearchMdl = [selectPatientMdl, updatePatientsList, startNewSearchMdl];
