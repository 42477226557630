/* eslint-disable */
import getTitleFromHost from '@ambulnz/document-title';
import 'core-js/es';
import 'core-js/features/string/repeat';
import 'core-js/stable';
import { setUser } from 'logger';
import moment from 'moment-timezone';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import App from './App';
import './i18n';
import { setNonTransport } from './redux/actions/requests.actions';
import { setSupportPhone } from './redux/actions/supportPhone.actions';
import {
  fetchAccessTokenSuccessfully,
  fetchCurrentUserSuccess,
  selectPatientExternalId,
} from './redux/actions/user.actions';
import { fetchUserAccountsSuccess } from './redux/actions/userAccount.actions';
import store, { history } from './redux/store/configureStore';
import reportWebVitals from './reportWebVitals';
import AuthService from './services/auth.service';
import * as ConfigService from './services/config.service';
import { initializeDatadogRum } from './services/datadog';
import SocketService from './services/socket.service';
import UserService from './services/user.service';
import UserSettingsService from './services/userSettings.service';
import status from './status';
import { USER_TYPES } from 'enums';
import { Analytics } from '@ambulnz/docgo-analytics';


const DEFAULT_AUTH_URL = 'https://newyork.ambulnz-dev.net';
export const docgoAnalyticsService = new Analytics();

moment.tz.setDefault(moment.tz.guess());

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector;
}

if (!String.prototype.repeat) {
  // eslint-disable-next-line no-extend-native
  String.prototype.repeat = count => {
    if (this == null) {
      throw new TypeError(`can't convert ${this} to object`);
    }

    let str = `${this}`;
    // To convert string to integer.
    count = +count;
    // Check NaN
    if (Number.isNaN(count)) {
      count = 0;
    }

    if (count < 0) {
      throw new RangeError('repeat count must be non-negative');
    }

    if (count === Infinity) {
      throw new RangeError('repeat count must be less than infinity');
    }

    count = Math.floor(count);

    if (str.length === 0 || count === 0) {
      return '';
    }

    // Ensuring count is a 31-bit integer allows us to heavily optimize the
    // main part. But anyway, most current (August 2014) browsers can't handle
    // strings 1 << 28 chars or longer, so:
    if (str.length * count >= 1 << 28) {
      throw new RangeError('repeat count must not overflow maximum string size');
    }

    const maxCount = str.length * count;
    count = Math.floor(Math.log(count) / Math.log(2));
    while (count) {
      str += str;
      count--;
    }
    str += str.substring(0, maxCount - str.length);
    return str;
  };
}

function noAccountsAssignedError() {
  return (
    <div>
      <h2>Error</h2>
      Your user does not have accounts assigned. Please contact support and refresh the page.
    </div>
  );
}

function isNavigateToMobileHealth(window) {
  return (
    window.ambulnzConfig.constants.PROMISED_WINDOW_ENABLED ||
    (window.ambulnzConfig.constants.MOBILE_HEALTH_SERVICE_ENABLED &&
      window.ambulnzConfig.userSettings.defaultToMedicalHealthServiceAsRequester) ||
    (window.ambulnzConfig.userSettings.defaultToMedicalHealthServiceAsRequester &&
      window.ambulnzConfig.userSettings.isMobileHealthServiceRequesterEnabled)
  );
}

async function loadDocgoAuthIfDevMode() {
  return new Promise((resolve, reject) => {
    if (typeof DocgoAuth !== 'undefined') return resolve(false);

    const script = document.createElement('script');
    script.src = `${DEFAULT_AUTH_URL}/docgo-auth/docgo-auth.js`;
    script.type = 'text/javascript';
    script.async = false;
    script.onload = () => resolve(true);
    script.onerror = reject;
    document.body.appendChild(script);
    return true;
  });
}

async function initializeDocgoAuth(isDevMode) {
  /* es-lint-disable-next-line no-undef*/
  window.DocgoAuth = DocgoAuth;
  await window.DocgoAuth.login({
    clientId: 'requester',
    orderServiceUrl: isDevMode ? `${DEFAULT_AUTH_URL}/` : '/',
  });
  document.title = getTitleFromHost('Request');
}

async function configureAmbulnz() {
  window.ambulnzConfig = await ConfigService.init();
  window.ambulnzConfig.isAnExternalApp = window !== window.parent;
}

function handleSelectedAccount() {
  const selectedAccount = store.getState().userAccount.selectedUserAccount;
  ConfigService.updateTenantedBaseUrls(selectedAccount);
}

async function configureAmbulnzConstants() {
  window.ambulnzConfig.constants = await ConfigService.getCfgs(window.ambulnzConfig);
}

function handleUserAccounts(userAccounts) {
  if (!userAccounts || !userAccounts.length) {
    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(noAccountsAssignedError());
    return;
  }
  store.dispatch(fetchUserAccountsSuccess(userAccounts)); // or dispatch(selectUserAccount(account)) if selected
}

async function renderApp() {
  const isDevMode = await loadDocgoAuthIfDevMode();
  if (typeof DocgoAuth === 'undefined') return;

  await initializeDocgoAuth(isDevMode);
  await configureAmbulnz();

  const authService = AuthService.build();
  const { ssoConfig, patient, hostname } = await authService.initialize();

  window.ambulnzConfig.navbarEnabled = ssoConfig.navbar_enabled;

  const userAccounts = [{ id: ssoConfig.account_id }];
  handleUserAccounts(userAccounts);

  const accessToken = await window.DocgoAuth.getAccessToken();
  store.dispatch(fetchAccessTokenSuccessfully(accessToken));

  handleSelectedAccount();

  await configureAmbulnzConstants();
  
  const mixpanelKey = window.ambulnzConfig.constants.DARA_MIXPANEL_TOKEN;
  const analyticsConfig = {
    key: mixpanelKey,
    debug: false,
    enabled: !!mixpanelKey,
  };
  docgoAnalyticsService.init(analyticsConfig);

  const phone =
    hostname && hostname.phone_number ? hostname.phone_number : window.ambulnzConfig.constants.REQUEST_ETA_PHONE;
  store.dispatch(setSupportPhone(phone));

  const user = await UserService.getAuthenticatedUser();
  setUser({ id: user.id, username: user.email });
  store.dispatch(fetchCurrentUserSuccess(user));
  docgoAnalyticsService.identify(user.id);
  docgoAnalyticsService.setPeople(user, userAccounts[0].id);

  const userSettingsService = new UserSettingsService(user);
  window.ambulnzConfig.userSettings = userSettingsService.settings;

  const fhirPatient = store.getState().user.fhirPatient;
  const patientExternalId = fhirPatient || patient;

  const patientSearchFromEpicLaunchAllowed = window.location.href.indexOf('edit') === -1;

  if (patientSearchFromEpicLaunchAllowed && patientExternalId) {
    store.dispatch(selectPatientExternalId(patientExternalId));
  }

  status(store.dispatch);
  SocketService.init(store);

  initializeDatadogRum(user);

  function shouldRedirectToMobileHealth() {
    const url = window.location.href;
    const isEditing = url.includes('edit');
    const isTransport = url.includes('transport');
    const isMobileHealth = url.includes('mobile-health');

    return isNavigateToMobileHealth(window) && !isEditing && !isTransport && !isMobileHealth;
  }

  function redirectToAppropriatePage() {
    const searchParams = '';
    const redirectTo =
      user?.userType === USER_TYPES.REQUESTER &&
      window.ambulnzConfig?.constants?.ON_DEMAND_ACCOUNT_IDS?.includes(ssoConfig.account_id)
        ? `/mobile-health-requester${searchParams}`
        : `/requester/mobile-health${searchParams}`;

    window.location.href = redirectTo;
  }

  if (shouldRedirectToMobileHealth()) {
    redirectToAppropriatePage();
  }

  if (window.location.href.indexOf('transport') !== -1) {
    window.history.pushState('', '', '/requester/');
  }

  if (window.location.href.indexOf('mobile-health') !== -1) {
    store.dispatch(setNonTransport(true));
  }


  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route exact path="/requests/:id/edit" component={App} />
          <Route exact path="/requests/:id" component={App} />
          <Route exact path="/mobile-health/:id/edit" component={App} />
          <Route exact path="/mobile-health" component={App} />
          <Route exact path="/transport" component={App} />
          <Route exact path="/requests" component={App} />
          <Route exact path="/" component={App} />
        </Switch>
      </Router>
    </Provider>,
  );
}

renderApp();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
reportWebVitals();

