import { createAction } from '@reduxjs/toolkit';

export const lightsAndSirensChange = createAction('[Requests] lightsAndSirensChange');
export const setLightsAndSirens = createAction('[Requests] setLightsAndSirens');
export const getRequests = createAction('[Requests] getRequests');
export const getRequestsSuccess = createAction('[Requests] getRequestsSuccess');
export const getRequest = createAction('[Requests] getRequest');
export const getRequestSuccess = createAction('[Requests] getRequestSuccess');
export const editRequest = createAction('[Requests] editRequest');
export const editRequestSuccess = createAction('[Requests] editRequestSuccess');
export const createCancelComment = createAction('[Requests] createCancelComment');
export const updateRequest = createAction('[Requests] updateRequest');
export const updateRequestSuccess = createAction('[Requests] updateRequestSuccess');
export const createRequestSuccess = createAction('[Requests] createRequestSuccess');
export const createShareLink = createAction('[Requests] createShareLink');
export const createShareLinkSuccess = createAction('[Requests] createShareLinkSuccess');
export const loadShareLink = createAction('[Requests] loadShareLink');
export const updateReturnRequestSuccess = createAction('[Requests] updateReturnRequestSuccess');
export const setTriggeredBy = createAction('[Requests] setTriggeredBy');
export const asyncCancelSimilarRequest = createAction('[Requests] asyncCancelSimilarRequest');
export const asyncCancelSimilarRequestSuccess = createAction('[Requests] asyncCancelSimilarRequestSuccess');
export const asyncGetSimilarRequests = createAction('[Requests] asyncGetSimilarRequests');
export const getSimilarRequestsSuccess = createAction('[Requests] getSimilarRequestsSuccess');
export const setIsBillerVerified = createAction('[Requests] setIsBillerVerified');
export const setIsPcrVerified = createAction('[Requests] setIsPcrVerified');
export const roundTripChange = createAction('[Requests] roundTripChange');
export const asyncRoundTripChange = createAction('[Requests] asyncRoundTripChange');
export const asyncRoundTripChangeSuccess = createAction('[Requests] asyncRoundTripChangeSuccess');
export const getSchedule = createAction('[Requests] getSchedule');
export const getScheduleSuccess = createAction('[Requests] getScheduleSuccess');
export const setStatus = createAction('[Requests] setStatus');
export const setProvider = createAction('[Requests] setProvider');
export const loadCoverages = createAction('[Requests] loadCoverages');
export const setWillCallReturn = createAction('[Requests] setWillCallReturn');
export const setReturnRequestCanBeActivatedByPatient = createAction(
  '[Requests] setReturnRequestCanBeActivatedByPatient',
);
export const setRequestCanBeActivatedByPatient = createAction('[Requests] setRequestCanBeActivatedByPatient');
export const getRequestCharges = createAction('[Requests] getRequestCharges');
export const getRequestChargesSuccess = createAction('[Requests] getRequestChargesSuccess');
export const asyncSetRequestCanBeActivatedByPatient = createAction('[Requests] asyncSetRequestCanBeActivatedByPatient');
export const setNonTransport = createAction('[Requests] setNonTransport');
export const fetchRequestVerifications = createAction('[Requests] fetchRequestVerifications');
export const fetchRequestVerificationsSuccess = createAction('[Requests] fetchRequestVerificationsSuccess');
