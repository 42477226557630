import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';

import PhysicianCertificationStatement from './PhysicianCertificationStatement';

const PCSDialog = ({ prefilledData, open, onSubmit, onCancel, ...props }) => {
  const [formData, setFormData] = useState(prefilledData);

  useEffect(() => {
    setFormData(prefilledData);
  }, [prefilledData]);

  return (
    <Dialog maxWidth={false} aria-labelledby="medicalNecessity-modal" open={open}>
      <DialogContent style={{ width: 800 }}>
        <PhysicianCertificationStatement
          {...props}
          onSubmit={onSubmit}
          onCancel={onCancel}
          formData={formData}
          setFormData={setFormData}
          onTrack={props.onTrack}
        />
      </DialogContent>
    </Dialog>
  );
};

PCSDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default PCSDialog;
