import schema from 'schm';
import computed from 'schm-computed';
import moment from 'moment';
import methods from 'schm-methods';

import { RequestStatus } from '../enums';

const statusMap = {
  50: 'Will Call',
  25: 'Scheduled',
  100: 'Turndown',
  0: 'Created',
  275: 'Needs Assignment',
  250: 'Needs Assignment',
  900: 'Completed',
  400: 'En Route',
  500: 'Cancelled',
};

const request = schema(
  {
    billerVerifiedBy: Object,
    pcrVerifiedBy: Object,
    callTypeId: Number,
    canRequestBeActivatedByPatient: false,
    careProvidedAtReceivingFacility: Boolean,
    careProvidedAtReceivingFacilityDescription: String,
    chiefComplaintId: Number,
    destinationDepartmentId: Number,
    destinationPlace: Object,
    doRoomNumber: String,
    erBound: Boolean,
    estimatedWaitForReturnMins: Number,
    exportedToZoll: false,
    id: Number,
    isLightsAndSirens: false,
    isWillCall: Boolean,
    isWillCallReturn: false,
    offers: Object,
    originalRequestId: Number,
    patientId: Number,
    pickupDepartmentId: Number,
    pickupPlace: Object,
    providerId: String,
    providerSystemId: String,
    puRoomNumber: String,
    requestedAppointmentTime: Date,
    requestedArrivalTime: Date,
    requesterAgency: Object,
    requesterAgencyId: Number,
    requesterId: Number,
    returnRequestId: Number,
    roundTrip: Boolean,
    scheduleId: Number,
    serviceTypeIds: Array,
    status: Number,
    therapistEquipmentIds: Array,
    type: Number,
    therapistEquipment: [Object],
    telehealthUrl: String,
    telehealthDetail: Object,
  },
  computed({
    validForUpdateSchedule: values =>
      [
        RequestStatus.CREATED,
        RequestStatus.NEEDS_ASSIGNMENT,
        RequestStatus.NEEDS_DISPATCHER_ACTION,
        RequestStatus.SCHEDULED,
        RequestStatus.AT_WORK,
      ].includes(values.status),
    isActive: values => [RequestStatus.OFFERED, RequestStatus.SCHEDULED, RequestStatus.AT_WORK].includes(values.status),
    statusName: values => statusMap[values.status],
    isScheduled: values => values.status === RequestStatus.SCHEDULED,
    isTurndown: values => values.status === RequestStatus.TURNDOWN,
    isWillCallStatus: values => values.status === RequestStatus.WILL_CALL,
    isCompleted: values => values.status === RequestStatus.COMPLETED,
    isCancelled: values => values.status === RequestStatus.CANCELLED,
    inProgress: values => values.status === RequestStatus.AT_WORK,
    needsAssignment: values =>
      [RequestStatus.CREATED, RequestStatus.NEEDS_ASSIGNMENT, RequestStatus.NEEDS_DISPATCHER_ACTION].includes(
        values.status,
      ),
    invalidForGetETA: values =>
      [RequestStatus.AT_WORK, RequestStatus.OFFERED, RequestStatus.COMPLETED].includes(values.status) ||
      ([RequestStatus.TURNDOWN, RequestStatus.CANCELLED].includes(values.status) &&
        !window.ambulnzConfig.constants.ENABLE_REACTIVATION),
  }),
  methods({
    returnTripArrivalTime: (values, passedRequestedArrivalTime) => {
      const requestedArrivalTime = passedRequestedArrivalTime || values.requestedArrivalTime;

      return moment(requestedArrivalTime).add(values.estimatedWaitForReturnMins, 'minutes').toISOString();
    },
  }),
);

export default request;
