import { mergeMap, switchMap, map, filter } from 'rxjs/operators';
import { from as from$, of as of$, concat as concat$ } from 'rxjs';
import { ETA_TYPES } from '../../enums';
import {
  updateRequest,
  updateRequestFromSchedule,
  updateSchedule,
  updateBillerVerified,
  updatePcrVerified,
  updateRequestTelehealthLik,
  deletePcrVerified,
} from './helpers/request.helper';
import { updateComments } from './helpers/comment.helper';
import * as locationActions from '../actions/location.actions';
import * as infoboxActions from '../actions/infobox.actions';
import * as requestsActions from '../actions/requests.actions';
import * as etaActions from '../actions/eta.actions';
import * as errorActions from '../actions/error.actions';
import * as mapActions from '../actions/map.actions';
import * as formFlowActions from '../actions/formFlow.actions';
import * as serviceActions from '../actions/service.actions';
import { errorPipe } from './epicsUtil';
import { needsScheduleRecalc, getRequestId, upsertSchedule, submitRooms } from './updateRequestUtil';
import { isLocationValid } from '../../validators/location.validator';
import RequestService from '../../services/request.service';
import { buildSchedulePayload } from '../../parsers/schedule.parser';

export const autoSaveRoomNumberEpic = (action$, store) =>
  action$.pipe(
    filter(locationActions.asyncAutoSaveRoomNumber.match),
    filter(() => getRequestId(store)),
    switchMap(() => updateComments(store)),
    switchMap(() =>
      submitRooms(store).pipe(
        map(() => locationActions.asyncAutoSaveRoomNumberSuccess()),
        errorPipe,
      ),
    ),
  );

export const autoSaveTelehealthLinkEpic = (action$, store) =>
  action$.pipe(
    filter(serviceActions.setTelehealthLink.match),
    switchMap(() => updateRequestTelehealthLik(store, { url: store.value.service.telehealthLink })),
    mergeMap(request => concat$(of$(errorActions.onError(request)))),
    errorPipe,
  );

// TODO: this is triggering a request update.
export const autoSaveBillerVerifiedEpic = (action$, store) =>
  action$.pipe(
    filter(infoboxActions.asyncAutoSaveBillerVerified.match),
    switchMap(() => updateBillerVerified(store)),
    mergeMap(response =>
      concat$(of$(infoboxActions.asyncAutoSaveBillerVerifiedSuccess()), of$(errorActions.onError(response))),
    ),
  );

export const autoSavePcrVerifiedEpic = (action$, store) =>
  action$.pipe(
    filter(infoboxActions.asyncAutoSavePcrVerified.match),
    filter(() => store.value.requests.isPcrVerified),
    switchMap(() => updatePcrVerified(store)),
    mergeMap(response =>
      concat$(of$(infoboxActions.asyncAutoSavePcrVerifiedSuccess()), of$(errorActions.onError(response))),
    ),
  );

export const autoSavePcrUnverifiedEpic = (action$, store) =>
  action$.pipe(
    filter(infoboxActions.asyncAutoSavePcrVerified.match),
    filter(() => !store.value.requests.isPcrVerified),
    filter(() => store.value.requests.requestPcrVerification),
    switchMap(() => deletePcrVerified(store)),
    mergeMap(response =>
      concat$(of$(infoboxActions.asyncAutoSavePcrVerifiedSuccess()), of$(errorActions.onError(response))),
    ),
  );

export const saveLocationChangeOnCompletedRequestEpic = (action$, store) =>
  action$.pipe(
    filter(mapActions.setMapLocationSuccess.match),
    filter(() => !store.value.formFlow.loadRequestInProgress),
    filter(() => store.value.requests.request.isCompleted),
    switchMap(() => updateRequest(store)),
    mergeMap(request =>
      concat$(
        of$(requestsActions.updateRequestSuccess(request)),
        of$(infoboxActions.submitPatientInfoSuccess(request.id)),
        of$(errorActions.onError(request)),
      ),
    ),
    errorPipe,
  );

export const onPickupTimeChangeRequiresUpdateScheduleEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [
        mapActions.setMapFromWaypoint.match,
        mapActions.setMapToWaypoint.match,
        etaActions.setDateTime.match,
        etaActions.setEtaType.match,
      ].some(match => match(action)),
    ),
    filter(() => !store.value.formFlow.loadRequestInProgress),
    filter(() => !store.value.eta.ETAWasAcceptedState),
    filter(() => store.value.eta.etaType === ETA_TYPES.PICKUP),
    filter(() => isLocationValid(store.value)),
    filter(() => needsScheduleRecalc(store.value)),
    switchMap(() => updateSchedule(store)),
    switchMap(schedule =>
      updateRequestFromSchedule(schedule, store).pipe(
        mergeMap(request =>
          concat$(
            of$(requestsActions.updateRequestSuccess(request)),
            of$(errorActions.onError(schedule)),
            of$(formFlowActions.autoSaveInProgress(false)),
            of$(errorActions.onError(request)),
          ),
        ),
        errorPipe,
      ),
    ),
  );

export const onAppointmentTimeChangeRequiresUpdateScheduleEpic = (action$, store) => {
  let requestedArrivalTime;
  return action$.pipe(
    filter(action => [etaActions.setAppmtTime.match, etaActions.setEtaType.match].some(match => match(action))),
    filter(() => store.value.eta.etaType === ETA_TYPES.APPOINTMENT),
    filter(() => getRequestId(store)),
    switchMap(() => from$(RequestService.getRequestedArrivalTime(getRequestId(store), store.value))),
    switchMap(response => {
      requestedArrivalTime = response.estimatedArrivalTime;
      return from$(
        upsertSchedule(store.value, {
          ...buildSchedulePayload(store.value),
          ...{ pickupTime: requestedArrivalTime },
        }),
      );
    }),
    switchMap(schedule =>
      updateRequest(store, {
        requestedArrivalTime,
        requestedAppointmentTime: store.value.eta.requestedAppointmentTime,
      }).pipe(
        mergeMap(request =>
          concat$(
            of$(etaActions.getRequestedArrivalTimeSuccess(requestedArrivalTime)),
            of$(requestsActions.updateRequestSuccess(request)),
            of$(errorActions.onError(request)),
            of$(errorActions.onError(schedule)),
            of$(formFlowActions.autoSaveInProgress(false)),
          ),
        ),
        errorPipe,
      ),
    ),
  );
};
