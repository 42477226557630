import withValidations from 'components/common/withValidations';
import Frame from './RoomNumber.frame';

const validations = {
  puRoomNumber: value => {
    if (value && value.length > 50) {
      return 'Cannot exceed 50 characters';
    }
    return null;
  },
  doRoomNumber: value => {
    if (value && value.length > 50) {
      return 'Cannot exceed 50 characters';
    }
    return null;
  },
};

export default withValidations(validations)(Frame);
