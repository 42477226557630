import { from as from$, of as of$ } from 'rxjs';

import RequestService from '../../../services/request.service';
import OfferService from '../../../services/offer.service';
import AttachmentApiService from '../../../services/attachmentApi.service';
import {
  parseCreateRequestPayload,
  parseRequest,
  parseRequestLocations,
  parseRequestWithoutRequestedArrivalTime,
} from '../../../parsers/request.parser';
import { parseInfoBoxForSubmit, parseRooms } from '../../../parsers/infobox.parser';
import { buildSchedulePayload, buildActivateCandidatePayload } from '../../../parsers/schedule.parser';
import { RequestStatus } from '../../../enums';
import { getChiefComplaintById } from '../../../services/chiefComplaint.service';

export const location = store => store.value.location;
export const rooms = store => parseRooms(store.value);
export const requestId = store => store.value.requests.request.id;
export const createdOfferId = store => store.value.offers.offers.find(offer => offer.offerStatus.name === 'created').id;
export const updateRequest = (store, payload) =>
  from$(RequestService.updateRequest(requestId(store), payload || parseRequest(store.value)));

export const updateRequestTelehealthLik = (store, payload) =>
  from$(RequestService.updateRequestTelehealthLink(requestId(store), payload));

export const updateRequestWithoutRequestedArrivalTime = store =>
  from$(RequestService.updateRequest(requestId(store), parseRequestWithoutRequestedArrivalTime(store.value)));

export const updateLightsAndSirens = store =>
  from$(
    RequestService.updateRequest(requestId(store), {
      isLightsAndSirens: store.value.requests.request.isLightsAndSirens,
    }),
  );

export const updateTransportReasons = store =>
  from$(
    RequestService.updateRequest(requestId(store), {
      transportReasons: store.value.service.transportReasons,
      therapistEquipmentIds: store.value.service.therapistEquipmentIds,
    }),
  );

export const updateTherapistEquipmentIds = store =>
  from$(
    RequestService.updateRequest(requestId(store), {
      therapistEquipmentIds: store.value.service.therapistEquipmentIds,
    }),
  );

export const updateCallType = store =>
  from$(
    RequestService.updateRequest(requestId(store), {
      callTypeId: store.value.service.callTypeId,
      isLightsAndSirens: store.value.requests.request.isLightsAndSirens,
      transportReasons: store.value.service.transportReasons,
    }),
  );

export const updateRequestFromSchedule = (schedule, store) =>
  schedule && !schedule.error ? updateRequest(store) : of$(store.value.requests.request);

export const updateRequestLocations = store =>
  from$(RequestService.updateRequest(requestId(store), parseRequestLocations(store.value)));

export const updateBillerVerified = store =>
  from$(
    RequestService.updateBillerVerified(requestId(store), {
      isBillerVerified: store.value.requests.isBillerVerified,
    }),
  );

export const getRequestVerifications = store => from$(RequestService.getRequestVerifications(requestId(store)));

export const updatePcrVerified = store => {
  const pcrTypeId = window.ambulnzConfig.requestVerificationTypes.PCR;
  return from$(
    RequestService.postPcrVerified(requestId(store), {
      typeId: pcrTypeId,
    }),
  );
};

export const deletePcrVerified = store => {
  const verificationId = store.value.requests.requestPcrVerification.id;
  return from$(RequestService.deletePcrVerified(requestId(store), verificationId));
};

export const submitRequest = store =>
  from$(
    RequestService.submit(
      requestId(store),
      parseInfoBoxForSubmit(store.value),
      store.value.agency.requesterAgencyGroup,
    ),
  );

export const duplicateRequest = store => from$(RequestService.duplicateRequest(requestId(store)));

export const createRequest = store => from$(RequestService.createRequest(parseCreateRequestPayload(store.value)));

export const getRequest = store => from$(RequestService.getRequest(requestId(store)));

export const getRequestById = id => from$(RequestService.getRequest(id));

export const updateSchedule = store =>
  from$(RequestService.updateSchedule(requestId(store), buildSchedulePayload(store.value)));

export const acceptEta = (store, etaId) =>
  from$(RequestService.acceptEta(store.value.requests.request.id, buildActivateCandidatePayload(store.value, etaId)));

export const getShareLink = store => from$(RequestService.getShareLink(requestId(store)));

export const getAttachments = id => from$(AttachmentApiService.getAttachments(id));
export const getSignatures = id => from$(AttachmentApiService.getSignatures(id));

export const getSimilarRequests = id => from$(RequestService.getSimilarRequests(id));

export const createShareLink = store => from$(RequestService.createShareLink(requestId(store), location(store).from));

export const cancelRequest = (store, reason) => from$(RequestService.cancelRequest(requestId(store), reason));

export const getSchedule = store => from$(RequestService.getSchedule(requestId(store)));

export function updateDepartments(store) {
  return updateRequest(store, {
    pickupDepartmentId: store.value.requests.pickupDepartmentId || null,
    destinationDepartmentId: store.value.requests.destinationDepartmentId || null,
  });
}

export function unReserve(store) {
  return from$(RequestService.unReserve(requestId(store), RequestStatus.NEEDS_ASSIGNMENT));
}

export function retractOffer(store) {
  return from$(OfferService.retractOffer(createdOfferId(store)));
}

export async function parseEpicIntegrationChiefComplaint(payload) {
  const chiefComplaint = await getChiefComplaintById(payload.chiefComplaint.id);
  return {
    id: 0,
    name: chiefComplaint.name,
    type: chiefComplaint.typeId,
  };
}
