import { createReducer } from 'redux-act';
import { get } from 'lodash';
import * as action from '../actions/location.actions';
import * as locationComponentActions from '../../components/PickupDestination/redux/actions/component.actions';
import facility from '../../schemas/facility.schema';
import prediction from '../../schemas/prediction.schema';
import address from '../../schemas/address.schema';
import location from '../../schemas/location.schema';
import geopointPrediction from '../../schemas/geopointPrediction.schema';
import * as R from 'ramda';

export const initialState = {
  from: '',
  fromSelected: {},
  pickupPlace: null,
  fromItems: [],
  pickupFacilities: [],
  to: '',
  toSelected: {},
  destinationPlace: null,
  toItems: [],
  destinationFacilities: [],
  erBound: false,
  searchPickupLocationInProgress: false,
  searchDestinationInProgress: false,
  manualPickupSecs: '',
  manualDropOffSecs: '',
  puRoomNumber: '',
  doRoomNumber: '',
  destinationAutoFocus: false,
  pickupDepartments: [],
  destinationDepartments: [],
  isDestinationConfirmed: false,
  pickupDangerFlag: false,
  pickupDangerFlagReason: '',
  pickupDangerFlagReasonId: null,
  destinationDangerFlag: false,
  destinationDangerFlagReason: '',
  destinationDangerFlagReasonId: null,
};

const facilityType = payload =>
  payload.emsFacilityTypeId ||
  get(payload, '0.emsFacility.typeId', null) ||
  get(payload, 'facilityLocation.emsFacility.typeId', null);

const locationReducer = createReducer(
  {
    [action.queryPickupLocations]: (state, payload) => ({
      ...state,
      from: payload,
      fromSelected: {},
    }),
    [action.setSearchPickupLocationInProgress]: (state, payload) => ({
      ...state,
      searchPickupLocationInProgress: payload,
    }),
    [action.pickupFacilitiesRetrieved]: (state, payload) => {
      return {
        ...state,
        pickupFacilities: payload.map(facility.parse).filter(v => v.id),
      };
    },
    [action.pickupPredictionsRetrieved]: (state, payload) => {
      return {
        ...state,
        fromItems: state.pickupFacilities.concat(payload ? payload.map(prediction.parse) : []),
      };
    },
    [action.pickupGeopointPredictionsRetrieved]: (state, payload) => {
      return {
        ...state,
        fromItems: payload ? payload.map(geopointPrediction.parse) : [],
      };
    },
    [action.clearPickupLocation]: state => ({
      ...state,
      from: '',
      fromSelected: {},
    }),
    [action.queryDestinations]: (state, payload) => ({
      ...state,
      to: payload,
      toSelected: {},
    }),
    [action.setDestinationConfirmation]: (state, payload) => ({
      ...state,
      isDestinationConfirmed: payload,
    }),
    [action.setSearchDestinationInProgress]: (state, payload) => ({
      ...state,
      searchDestinationInProgress: payload,
    }),
    [action.setPickupAddress]: (state, payload) => {
      const pluckedAddress = R.pick(['city', 'country', 'postalCode', 'state', 'street1'], payload.address);
      const filteredAddress = R.filter(Boolean, pluckedAddress);
      return {
        ...state,
        from:
          payload.typeId === state.placeTypeEnum.geopoint
            ? `${payload.locationGps.lat}, ${payload.locationGps.lng}`
            : payload.formattedAddress(),
        fromSelected: {
          name: payload.formattedAddress(),
          facility: payload.name,
          facilityFull: payload,
          postalCode: payload.address.postalCode,
          type: facilityType(payload),
          typeId: payload.typeId,
          addressObj: filteredAddress,
        },
      };
    },
    [action.destinationsFacilitiesRetrieved]: (state, payload) => {
      return { ...state, destinationFacilities: payload.map(facility.parse).filter(v => v.id) };
    },
    [action.destinationsPredictionsRetrieved]: (state, payload) => ({
      ...state,
      toItems: state.destinationFacilities.concat(payload ? payload.map(prediction.parse) : []),
    }),
    [action.destinationGeopointPredictionsRetrieved]: (state, payload) => ({
      ...state,
      toItems: payload ? payload.map(geopointPrediction.parse) : [],
    }),
    [action.clearDestinationLocation]: state => ({
      ...state,
      to: '',
      toSelected: {},
    }),
    [action.setDestinationAddress]: (state, payload) => {
      const pluckedAddress = R.pick(['city', 'country', 'postalCode', 'state', 'street1'], payload.address);
      const filteredAddress = R.filter(Boolean, pluckedAddress);
      return {
        ...state,
        to:
          payload.typeId === state.placeTypeEnum.geopoint
            ? `${payload.locationGps.lat}, ${payload.locationGps.lng}`
            : payload.formattedAddress(),
        toSelected: {
          name: payload.formattedAddress(),
          facility: payload.name,
          facilityFull: payload,
          postalCode: payload.address.postalCode,
          type: facilityType(payload),
          typeId: payload.typeId,
          addressObj: filteredAddress,
        },
      };
    },
    [action.setAgencyPickupLocation]: (state, payload) => {
      const pluckedAddress = R.omit(['full'], address.parse(payload));
      const filteredAddress = R.filter(Boolean, pluckedAddress);
      return {
        ...state,
        from: address.parse(payload).full,
        fromSelected: {
          name: address.parse(payload).full,
          id: location.parse(payload).id,
          facility: payload[0].name,
          facilityFull: facility.parse(payload[0].emsFacility || {}),
          type: facilityType(payload),
          addressObj: filteredAddress,
        },
      };
    },
    [action.setAgencyDestinationLocation]: (state, payload) => {
      const pluckedAddress = R.omit(['full'], address.parse(payload));
      const filteredAddress = R.filter(Boolean, pluckedAddress);
      return {
        ...state,
        to: address.parse(payload).full,
        toSelected: {
          name: address.parse(payload).full,
          id: location.parse(payload).id,
          facility: payload[0].name,
          type: facilityType(payload),
          addressObj: filteredAddress,
        },
      };
    },
    [action.toggleERBound]: (state, payload) => ({
      ...state,
      erBound: payload,
    }),
    [action.setRoomNumber]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [action.setManualPickupTimeSecs]: (state, payload) => ({
      ...state,
      manualPickupSecs: payload,
    }),
    [action.setManualDropoffTimeSecs]: (state, payload) => ({
      ...state,
      manualDropOffSecs: payload,
    }),
    [action.loadRequestDetails]: (state, payload) => ({
      ...state,
      manualPickupSecs: String(payload.manualPickupSecs),
      manualDropOffSecs: String(payload.manualDropOffSecs),
    }),
    [action.setDestinationAutoFocus]: (state, payload) => ({
      ...state,
      destinationAutoFocus: payload,
    }),
    [action.clearSearchResults]: state => ({
      ...state,
      fromItems: [],
      pickupFacilities: [],
      toItems: [],
      destinationFacilities: [],
    }),
    [action.getPickupDepartmentsSuccess]: (state, payload) => ({
      ...state,
      pickupDepartments: payload,
    }),
    [action.getDestinationDepartmentsSuccess]: (state, payload) => ({
      ...state,
      destinationDepartments: payload,
    }),
    [locationComponentActions.clearPickupDepartment]: state => ({
      ...state,
      pickupDepartments: [],
    }),
    [locationComponentActions.clearDestinationDepartment]: state => ({
      ...state,
      destinationDepartments: [],
    }),
    [locationComponentActions.setPickupDangerousFlag]: (state, payload) => {
      return {
        ...state,
        pickupDangerFlag: true,
        pickupDangerFlagReason: payload,
      };
    },
    [action.setPickupDangerFlagId]: (state, payload) => {
      return {
        ...state,
        pickupDangerFlagReasonId: payload?.id,
      };
    },
    [locationComponentActions.unsetPickupDangerousFlag]: state => ({
      ...state,
      pickupDangerFlag: false,
      pickupDangerFlagReason: '',
    }),
    [locationComponentActions.setDestinationDangerousFlag]: (state, payload) => {
      return {
        ...state,
        destinationDangerFlag: true,
        destinationDangerFlagReason: payload,
      };
    },
    [action.setDestinationDangerFlagId]: (state, payload) => {
      return {
        ...state,
        destinationDangerFlagReasonId: payload?.id,
      };
    },
    [locationComponentActions.unsetDestinationDangerousFlag]: state => ({
      ...state,
      destinationDangerFlag: false,
      destinationDangerFlagReason: '',
    }),
    [action.fetchPlaceTypesSuccess]: (state, payload) => ({
      ...state,
      placeTypeEnum: payload.reduce((acc, currentValue) => {
        acc[currentValue.name] = currentValue.id;

        return acc;
      }, {}),
      placeTypeLookup: payload.reduce((acc, currentValue) => {
        acc[currentValue.id] = currentValue.name;

        return acc;
      }, {}),
    }),
    [action.setDestinationAsPickup]: state => ({
      ...state,
      toSelected: state.fromSelected,
    }),
    [action.resetLocation]: state => ({
      ...state,
      ...initialState,
    }),
    [action.resetDestination]: state => ({
      ...state,
      to: '',
      toSelected: {},
      destinationDepartments: [],
      doRoomNumber: '',
    }),
    [action.loadPickupCommentSuccess]: (state, payload) => {
      return {
        ...state,
        pickupDangerFlag: !!payload?.comment,
        pickupDangerFlagReason: payload?.comment.comment,
        pickupDangerFlagReasonId: payload?.id,
      };
    },
    [action.loadDestinationCommentSuccess]: (state, payload) => {
      return {
        ...state,
        destinationDangerFlag: !!payload?.comment,
        destinationDangerFlagReason: payload?.comment.comment,
        destinationDangerFlagReasonId: payload?.id,
      };
    },
  },
  initialState,
);

export default locationReducer;
