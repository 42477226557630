import React from 'react';
import AmbGrid from '../../../common/AmbGrid';
import AmbSwitch from 'components/common/AmbSwitch';

const PcrVerifiedFrame = ({
  loadedRequest,
  isPcrVerified,
  pcrVerifiedChange,
  disabled,
  isBillerVerified,
  pcrVerifiedBy,
  pcrVerifiedAt,
}) => {
  const wrapperStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 -12px 0 -12px',
    maxWidth: 'inherit',
    backgroundColor: '#f7f7f7',
    padding: 10,
    borderTop: 'rgba(0, 0, 0, 0.09) 1px solid',
  };

  const bgColor =
    isPcrVerified && isBillerVerified
      ? {
          backgroundColor: '#f3fcf9',
          borderBottom: '#33cc99 2px solid',
          borderLeft: '#33cc99 2px solid',
          borderRight: '#33cc99 2px solid',
        }
      : {
          backgroundColor: '#fff5f5',
          borderBottom: '#ff5252 2px solid',
          borderLeft: '#ff5252 2px solid',
          borderRight: '#ff5252 2px solid',
        };

  const getPcrWrapperStyles = () => {
    if (loadedRequest) {
      return {
        ...wrapperStyles,
        ...bgColor,
      };
    }
    return wrapperStyles;
  };

  return (
    <AmbGrid style={getPcrWrapperStyles()}>
      <label id="pcr-verified-label">
        <AmbSwitch
          id="pcr-verified-switch"
          onChange={e => pcrVerifiedChange(e.target.checked)}
          checked={isPcrVerified}
          disabled={disabled}
        />
        PCR VERIFIED
      </label>
      {isPcrVerified && (
        <div id="pcr-verified-by" style={{ fontSize: 12, textAlign: 'right', opacity: 0.8, lineHeight: 1 }}>
          <div>{`as of ${pcrVerifiedAt}`}</div>
          <div>{`by ${pcrVerifiedBy}`}</div>
        </div>
      )}
    </AmbGrid>
  );
};

export default PcrVerifiedFrame;
