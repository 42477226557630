import * as locationActions from '../../actions/location.actions';
import * as formFlowActions from '../../actions/formFlow.actions';
import * as infoboxActions from '../../actions/infobox.actions';

export const asyncAutoSaveSuccess =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (
      locationActions.asyncAutoSaveRoomNumberSuccess.match(action) ||
      infoboxActions.asyncAutoSaveBillerVerifiedSuccess.match(action) ||
      infoboxActions.asyncAutoSavePcrVerifiedSuccess.match(action)
    ) {
      dispatch(formFlowActions.autoSaveInProgress(false));
    }
  };

export const autoSaveCoverages =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (infoboxActions.autoSaveCoverages.match(action) && action.payload && getState().infobox.coveragesDirty) {
      dispatch(formFlowActions.autoSaveInProgress(true));
      dispatch(infoboxActions.updateCoverages());
    }
  };

export const setCoveragesDirty =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (infoboxActions.setPayerInfo.match(action) || infoboxActions.setPayerActive.match(action)) {
      dispatch(infoboxActions.setCoveragesDirty(true));
    }
  };

const middlewares = [asyncAutoSaveSuccess, autoSaveCoverages, setCoveragesDirty];
export default middlewares;
