import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ChiefComplaint from './ChiefComplaint.validations';
import * as chiefComplaintActions from '../../../redux/actions/chiefComplaint.actions';
import * as infoboxActions from '../../../redux/actions/infobox.actions';
import { chiefComplaintCollapse } from '../../../flags/collapse.flag';
import { otherOptionOfChiefComplaintDisabled } from '../../../flags/disabled.flag';
import { getChiefComplaints } from '../../../services/chiefComplaint.service';
import { CHIEF_COMPLAINT } from '../../../enums';
import { agencyRequiresChiefComplaint } from 'flags/valid.flag';

function ChiefComplaintContainer(props) {
  const [chiefComplaintList, setChiefComplaintList] = useState([]);

  useEffect(() => {
    let isMounted = true;

    async function getList() {
      const response = await getChiefComplaints();
      if (isMounted) {
        let fullChiefComplaintList = response.map(c => ({ id: c.id, name: c.name }));

        if (props.chiefComplaintId && !fullChiefComplaintList.find(e => e.id === props.chiefComplaintId)) {
          fullChiefComplaintList.unshift({
            id: props.chiefComplaintId,
            name: props.chiefComplaintName,
            disabled: true,
          });
        }
        fullChiefComplaintList = [
          {
            id: 0,
            name: 'Other',
            sticky: true,
            disabled: props.otherDisabled,
          },
          ...fullChiefComplaintList,
        ];

        setChiefComplaintList(fullChiefComplaintList);
      }
    }
    getList();
    return () => {
      // Cleanup function to cancel any subscriptions or asynchronous tasks
      isMounted = false;
    };
  }, [props.chiefComplaintId, props.chiefComplaintName, props.otherDisabled]);

  function handleOtherChange() {
    props.setChiefComplaint({
      id: 0,
      type: CHIEF_COMPLAINT.TYPES.NON_STANDARD,
    });
  }

  function handleStandardChange(id, name) {
    props.setChiefComplaint({
      name,
      id,
      type: CHIEF_COMPLAINT.TYPES.STANDARD,
    });

    props.saveChiefComplaint();
  }

  const setChange = ({ id, name }) => (id === 0 ? handleOtherChange() : handleStandardChange(id, name));
  const handleChange = ({ detail }) => (detail ? setChange(detail) : props.clearChiefComplaint());

  const [descriptionDirty, setDescriptionDirty] = useState(false);

  function handleDescriptionChange(event) {
    setDescriptionDirty(true);
    props.setChiefComplaint({
      id: 0,
      name: event.target.value,
      type: CHIEF_COMPLAINT.TYPES.NON_STANDARD,
    });
  }

  function handleDescriptionBlur() {
    if (descriptionDirty) {
      props.saveChiefComplaint();
      setDescriptionDirty(false);
    }
  }

  return (
    <ChiefComplaint
      {...props}
      chiefComplaintList={chiefComplaintList}
      handleChange={handleChange}
      handleDescriptionChange={handleDescriptionChange}
      handleDescriptionBlur={handleDescriptionBlur}
    />
  );
}

ChiefComplaintContainer.propTypes = {
  getChiefComplaintList: PropTypes.func.isRequired,
  setChiefComplaint: PropTypes.func.isRequired,
  clearChiefComplaint: PropTypes.func.isRequired,
  saveChiefComplaint: PropTypes.func.isRequired,
  chiefComplaintId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const otherTooltip = state =>
  otherOptionOfChiefComplaintDisabled(state)
    ? 'This chief complain can’t be selected anymore because the trip has been exported to zoll already.'
    : '';

function mapStateToProps(state) {
  const { request, chiefComplaintName, chiefComplaintId, chiefComplaintType } = state.requests;

  const nonStandard = chiefComplaintType === CHIEF_COMPLAINT.TYPES.NON_STANDARD;
  return {
    requestId: request.id,
    shouldCollapse: chiefComplaintCollapse(state),
    triggerValidation: state.formFlow.triggerValidation,
    title: chiefComplaintName || 'Patient Condition',
    chiefComplaintId,
    chiefComplaintName,
    showDescription: nonStandard,
    otherTitle: otherTooltip(state),
    agencyRequiresChiefComplaint: agencyRequiresChiefComplaint(state),
  };
}

export default connect(mapStateToProps, {
  ...chiefComplaintActions,
  ...infoboxActions,
})(ChiefComplaintContainer);
