import React from 'react';
import PropTypes from 'prop-types';
import { stateCodes } from '../../../../enums';
import AmbGrid from 'components/common/AmbGrid';
import AmbTextField from 'components/common/AmbTextField';
import AmbSelect from 'components/common/AmbSelect';
import AmbInputLabel from 'components/common/AmbInputLabel';
import { theme } from '../../../../theme';

class PatientAdditionalInfoFrame extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.hasErrors !== this.props.hasErrors) {
      this.props.handleError(nextProps.hasErrors);
    }

    if (nextProps.shouldValidateAll !== this.props.shouldValidateAll && nextProps.shouldValidateAll) {
      this.props.validateAll(nextProps);
    }

    if (nextProps.triggerValidation !== this.props.triggerValidation && nextProps.triggerValidation) {
      this.props.validateAll(nextProps);
    }

    if (this.props.agencyRequiresPatientInfo !== nextProps.agencyRequiresPatientInfo) {
      this.props.validateAll(nextProps);
    }

    if (
      nextProps.triggerPatientValidation !== this.props.triggerPatientValidation &&
      nextProps.triggerPatientValidation
    ) {
      this.props.validateAll(nextProps);
    }

    this.props.setSaveErrorMessage(nextProps.errors);
  }

  handleInputChange = e => this.props.inputChange({ [e.target.name]: e.target.value });

  handleInputBlur = () => {
    this.props.autoSavePatientAdditionalDetails();
  };

  handleZipChange = e => {
    if (isNaN(e.target.value)) {
      return;
    }

    this.handleInputChange(e);
  };

  render() {
    const { disabled } = this.props;
    const inputProps = {
      style: { ...theme.mixins.input },
    };
    const labelProps = {
      style: { ...theme.mixins.inputLabel },
      shrink: true,
    };
    const selectStyles = {
      position: 'relative',
      zIndex: 10,
      width: 'calc(100% - 32px)',
      cursor: 'pointer',
      minWidth: 16,
      userSelect: 'none',
      padding: '6px 32px 7px 0',
      MozApearance: 'none',
      WebkitAppearance: 'none',
      font: 'inherit',
      color: '#000',
      border: 0,
      margin: 0,
      display: 'block',
      boxSizing: 'content-box',
      verticalAlign: 'middle',
      WebkitTapHighlightColor: 'transparent',
      outline: 'none',
    };

    const stateOptions = stateCodes.map(option => (
      <option key={option} value={option.toString()}>
        {`${option}`}
      </option>
    ));

    return (
      <>
        <h4 style={{ marginBottom: 6 }}>Patient Address</h4>
        <AmbGrid style={{ flexWrap: 'wrap', justifyContent: 'space-between', gap: '8px' }}>
          <AmbGrid style={{ flex: '1 0 48%' }}>
            <AmbTextField
              InputProps={inputProps}
              InputLabelProps={labelProps}
              name="address1"
              value={this.props.address1}
              fullWidth
              label="Address 1"
              onChange={this.handleInputChange}
              onBlur={this.handleInputBlur}
              id="patientAddress1"
              disabled={disabled || null}
              error={!!this.props.errors.address1}
              subLabel={this.props.errors.address1}
              {...(this.props.isNonTransport ? { required: true } : {})}
            />
          </AmbGrid>
          <AmbGrid style={{ flex: '1 0 48%' }}>
            <AmbTextField
              InputProps={inputProps}
              InputLabelProps={labelProps}
              name="address2"
              value={this.props.address2}
              fullWidth
              label="Address 2"
              onChange={this.handleInputChange}
              onBlur={this.handleInputBlur}
              id="patientAddress2"
              disabled={disabled || null}
              error={!!this.props.errors.address2}
              subLabel={this.props.errors.address2}
            />
          </AmbGrid>

          <AmbGrid style={{ flex: '1 0 46%' }}>
            <AmbTextField
              InputProps={inputProps}
              InputLabelProps={labelProps}
              name="city"
              value={this.props.city}
              fullWidth
              label="City"
              onChange={this.handleInputChange}
              onBlur={this.handleInputBlur}
              id="patientCity"
              disabled={disabled || null}
              error={!!this.props.errors.city}
              subLabel={this.props.errors.city}
              {...(this.props.isNonTransport ? { required: true } : {})}
            />
          </AmbGrid>
          <AmbGrid style={{ flex: '0 0 20%' }}>
            <div
              style={{
                marginTop: -2,
                border: 0,
                display: 'inline-flex',
                padding: 0,
                position: 'relative',
                minWidth: 0,
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <AmbInputLabel
                htmlFor="patientState"
                style={theme.mixins.inputLabel}
                shrink
                error={!!this.props.errors.state}
                {...(this.props.isNonTransport ? { required: true } : {})}
              >
                State
              </AmbInputLabel>
              <AmbSelect
                name="state"
                value={this.props.state}
                onChange={this.handleInputChange}
                onBlur={this.handleInputBlur}
                id="patientState"
                disabled={disabled || null}
                error={!!this.props.errors.state}
                {...(this.props.isNonTransport ? { required: true } : {})}
                options={stateOptions}
                style={selectStyles}
              ></AmbSelect>
            </div>
          </AmbGrid>
          <AmbGrid style={{ flex: '0 0 24%' }}>
            <AmbTextField
              maxLength={5}
              InputLabelProps={labelProps}
              name="zip"
              value={this.props.zip}
              fullWidth
              label="Zip"
              onChange={this.handleZipChange}
              onBlur={this.handleInputBlur}
              id="patientZip"
              error={!!this.props.errors.zip}
              disabled={disabled || null}
              {...(this.props.isNonTransport ? { required: true } : {})}
            />
          </AmbGrid>
        </AmbGrid>
      </>
    );
  }
}

PatientAdditionalInfoFrame.propTypes = {
  inputChange: PropTypes.func.isRequired,
  autoSavePatientAdditionalDetails: PropTypes.func.isRequired,
  zip: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  handleError: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  errors: PropTypes.shape().isRequired,
  hasErrors: PropTypes.bool.isRequired,
  setSaveErrorMessage: PropTypes.func.isRequired,
  validateAll: PropTypes.func.isRequired,
};

export default PatientAdditionalInfoFrame;
