import { filter, map, switchMap } from 'rxjs/operators';
import { from as from$ } from 'rxjs';
import * as errorActions from '../../../../redux/actions/error.actions';
import * as locationActions from '../../../../redux/actions/location.actions';
import { errorPipe } from '../../../../redux/epics/epicsUtil';
import { requestId, updateDepartments } from '../../../../redux/epics/helpers/request.helper';
import * as PcrService from '../../../../services/pcr.service';
import PlaceTypeService from '../../../../services/placeTypeService';
import * as componentActions from '../actions/component.actions';
import {
  createPickupAddressComment,
  createDestinationAddressComment,
  getPickupAddressComment,
  getDestinationAddressComment,
  deletePickupAddressComment,
  deleteDestinationAddressComment,
} from './helpers/addressComment.helper';

export const getPcrStatus = (action$, store) =>
  action$.pipe(
    filter(componentActions.getPcrStatus.match),
    filter(() => requestId(store)),
    switchMap(() => PcrService.getPcrStatus(requestId(store))),
    map(response => componentActions.getPcrStatusSuccess(response)),
  );

export const autoSaveDepartmentEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [
        componentActions.selectPickupDepartment.match,
        componentActions.selectDestinationDepartment.match,
        componentActions.clearPickupDepartment.match,
        componentActions.clearDestinationDepartment.match,
      ].some(match => match(action)),
    ),
    filter(() => requestId(store)),
    filter(() => !store.value.formFlow.loadRequestInProgress),
    switchMap(() => updateDepartments(store)),
    map(response => errorActions.onError(response)),
  );

export const fetchPlaceTypeEpic = action$ =>
  action$.pipe(
    filter(locationActions.fetchPlaceTypes.match),
    switchMap(() =>
      from$(PlaceTypeService.get()).pipe(
        map(result => locationActions.fetchPlaceTypesSuccess(result)),
        errorPipe,
      ),
    ),
  );

export const autoSavePickupFlagEpic = (action$, store) =>
  action$.pipe(
    filter(componentActions.setPickupDangerousFlag.match),
    filter(() => store.value.location?.fromSelected),
    switchMap(() =>
      createPickupAddressComment(store).pipe(
        map(response => locationActions.setPickupDangerFlagId(response)),
        errorPipe,
      ),
    ),
  );

export const autoDeletePickupFlagEpic = (action$, store) =>
  action$.pipe(
    filter(componentActions.unsetPickupDangerousFlag.match),
    filter(() => store.value.location?.fromSelected),
    switchMap(() =>
      deletePickupAddressComment(store).pipe(
        map(() => locationActions.setPickupDangerFlagId(null)),
        errorPipe,
      ),
    ),
  );

export const autoSaveDestinationFlagEpic = (action$, store) =>
  action$.pipe(
    filter(componentActions.setDestinationDangerousFlag.match),
    filter(() => store.value.location?.toSelected),
    switchMap(() =>
      createDestinationAddressComment(store).pipe(
        map(response => locationActions.setDestinationDangerFlagId(response)),
        errorPipe,
      ),
    ),
  );

export const autoDeleteDestinationFlagEpic = (action$, store) =>
  action$.pipe(
    filter(componentActions.unsetDestinationDangerousFlag.match),
    filter(() => store.value.location?.toSelected),
    switchMap(() =>
      deleteDestinationAddressComment(store).pipe(
        map(() => locationActions.setDestinationDangerFlagId(null)),
        errorPipe,
      ),
    ),
  );

export const loadPickupCommentEpic = (action$, store) =>
  action$.pipe(
    filter(locationActions.loadPickupComment.match),
    switchMap(() =>
      getPickupAddressComment(store).pipe(
        map(response => locationActions.loadPickupCommentSuccess(response)),
        errorPipe,
      ),
    ),
  );

export const loadDestinationCommentEpic = (action$, store) =>
  action$.pipe(
    filter(locationActions.loadDestinationComment.match),
    switchMap(() =>
      getDestinationAddressComment(store).pipe(
        map(response => locationActions.loadDestinationCommentSuccess(response)),
        errorPipe,
      ),
    ),
  );
