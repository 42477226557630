import CommentService from 'services/comment.service';
import { from as from$, of as of$ } from 'rxjs';

export const createPickupAddressComment = store => {
  const comment = store.value.location.pickupDangerFlagReason;
  const { fromSelected } = store.value.location;
  const address = fromSelected.addressObj;
  return from$(CommentService.createAddressComment(address, comment));
};

export const createDestinationAddressComment = store => {
  const comment = store.value.location.destinationDangerFlagReason;
  const { toSelected } = store.value.location;
  const address = toSelected.addressObj;
  return from$(CommentService.createAddressComment(address, comment));
};

export const getPickupAddressComment = store => {
  const { fromSelected } = store.value.location;
  const address = fromSelected.addressObj;
  return address ? from$(CommentService.getAddressComment({ address })) : of$(null);
};

export const getDestinationAddressComment = store => {
  const { toSelected } = store.value.location;
  const address = toSelected.addressObj;
  return address ? from$(CommentService.getAddressComment({ address })) : of$(null);
};

export const deletePickupAddressComment = store => {
  return from$(CommentService.deleteAddressComment(store.value.location.pickupDangerFlagReasonId));
};

export const deleteDestinationAddressComment = store => {
  return from$(CommentService.deleteAddressComment(store.value.location.destinationDangerFlagReasonId));
};
