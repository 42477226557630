import moment from 'moment';

import * as serviceActions from '../../actions/service.actions';
import * as requestsActions from '../../actions/requests.actions';
import * as formFlowActions from '../../actions/formFlow.actions';
import * as etaActions from '../../actions/eta.actions';
import { lockedProvider } from '../../../components/ServiceLevel/ServiceLevel.flags';

const setTransportReasons =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (
      serviceActions.setTransportReasons.match(action) &&
      getState().requests.request.id &&
      !getState().formFlow.loadRequestInProgress &&
      !getState().formFlow.pendingRequestUpdate
    ) {
      dispatch(serviceActions.autoSaveTransportReasons());
      dispatch(formFlowActions.autoSaveInProgress(true));
    }
  };

const equipmentChange =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (
      serviceActions.equipmentChange.match(action) &&
      getState().requests.request.id &&
      !getState().formFlow.loadRequestInProgress &&
      !getState().formFlow.pendingRequestUpdate
    ) {
      dispatch(serviceActions.autoSaveTheraphistEquipmentIds());
      dispatch(formFlowActions.autoSaveInProgress(true));
    }
  };

const callTypeChange =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (serviceActions.callTypeChange.match(action)) {
      const state = getState();
      const selectedTransportReason = state.service.callTypeOptions.find(
        callType => callType.key === state.service.callTypeId,
      );
      const offset =
        selectedTransportReason?.defaultFromNowPickupTimeInMins ||
        state.agency.requesterAgencyGroup.defaultTimeOffsetMinutes ||
        5;

      dispatch(etaActions.setDateTime(moment().add(offset, 'minutes')));

      if (
        state.requests.request.id &&
        !state.formFlow.loadRequestInProgress &&
        !getState().formFlow.pendingRequestUpdate
      ) {
        dispatch(serviceActions.asyncAutoSaveCallType());
        dispatch(formFlowActions.autoSaveInProgress(true));
      }
    }
  };

const lightsAndSirensChange =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (
      requestsActions.lightsAndSirensChange.match(action) &&
      getState().requests.request.id &&
      !getState().formFlow.pendingRequestUpdate
    ) {
      dispatch(serviceActions.asyncAutoSaveLightsAndSirens());
    }
  };

const lockProviderMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    const lockProvider = service => dispatch(serviceActions.setLockedProvider(lockedProvider(service)));
    if (
      serviceActions.fetchProviderCapabilitesSuccess.match(action) &&
      getState().requests.request.id &&
      !getState().formFlow.loadRequestInProgress
    ) {
      lockProvider(getState().service);
    }

    if (requestsActions.createRequestSuccess.match(action)) {
      lockProvider(getState().service);
    }
  };

const middlewares = [setTransportReasons, equipmentChange, callTypeChange, lightsAndSirensChange, lockProviderMdl];

export default middlewares;
