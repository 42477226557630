import { datadogRum } from '@datadog/browser-rum';
import { VERSION } from '../version';
import store from '../redux/store/configureStore';
import { USER_TYPES } from 'enums';

export function initializeDatadogRum(user) {
  const datadogRumAppId = window.ambulnzConfig.constants.DATADOG_RUM_APP_ID;
  const datadogRumEnable = window.ambulnzConfig.constants.DATADOG_REQUESTER_RUM_ENABLE;
  const datadogRumClientToken = window.ambulnzConfig.constants.DATADOG_RUM_CLIENT_TOKEN;
  const datadogRumEnv = window.ambulnzConfig.constants.DATADOG_RUM_ENV;
  const datadogRumSampleRate = Number(window.ambulnzConfig.constants.DATADOG_REQUESTER_RUM_SAMPLE_RATE);
  const datadogRumSessionSampleRate = Number(window.ambulnzConfig.constants.DATADOG_REQUESTER_RUM_SESSION_SAMPLE_RATE);
  const selectedAccount = store.getState().userAccount.selectedUserAccount;
  if (datadogRumEnable) {
    datadogRum.init({
      applicationId: datadogRumAppId,
      clientToken: datadogRumClientToken,
      site: 'datadoghq.com',
      service: 'requester',
      env: datadogRumEnv,
      version: VERSION,
      sessionSampleRate: datadogRumSampleRate,
      sessionReplaySampleRate: datadogRumSessionSampleRate,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['all'],
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        '/https://.*.ambulnz-dev.net/',
        '/https://.*.ambulnz.net/',
        '/https://.*.ca1.proda6-dev.net/',
        '/https://.*.us1.proda6.net/',
      ],
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      storeContextsAcrossPages: true,
      usePartitionedCrossSiteSessionCookie: true,
    });

    datadogRum.setUser({
      id: user.id,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      role:
        user.userType === USER_TYPES.DISPATCHER
          ? 'Dispatcher'
          : user.userType === USER_TYPES.REQUESTER
          ? 'Requester'
          : '',
      account: selectedAccount ? selectedAccount : '',
    });
  }
}
