import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import withValidations from '../../common/withValidations';
import Frame from './frame';

const validations = {
  description: (value, props) => {
    if (props.checked && !value) {
      return 'Care Provided at Receiving Facility must have a description';
    }
    if (value && value.length > 1000) {
      return 'Cannot exceed 1000 characters';
    }
    return null;
  },
};

const CareProvidedAtReceivingFacility = props => {
  useEffect(
    () => {
      if (props.triggerValidation) props.validateAll(props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.triggerValidation],
  );
  useEffect(
    () => {
      props.validateAll(props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.checked],
  );
  useEffect(
    () => {
      props.setSaveErrorMessage(props.errors);
      props.onValidationError(props.hasErrors);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.hasErrors],
  );
  return <Frame {...props} />;
};

CareProvidedAtReceivingFacility.propTypes = {
  hasErrors: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  errors: PropTypes.shape().isRequired,
  triggerValidation: PropTypes.bool.isRequired,
  validateAll: PropTypes.func.isRequired,
  setSaveErrorMessage: PropTypes.func.isRequired,
  onValidationError: PropTypes.func.isRequired,
};

export default withValidations(validations)(CareProvidedAtReceivingFacility);
