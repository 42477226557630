import ApiService from './api.service';
import { COMMENTS } from '../enums';

class CommentService {
  static async create(requestId, commentPayload, commentType = COMMENTS.TYPES.REQUEST) {
    const comment = commentPayload;
    const payload = {
      comment,
      commentType,
    };
    const path = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/comment`;

    let response;
    try {
      response = await ApiService.post(path, payload);
    } catch (e) {
      console.log('failed to post comments', e);
      response = {};
    }

    return response;
  }

  static async getComment(requestId) {
    const path = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/comment?commentType=1`;

    const response = await ApiService.get(path);
    return response.json();
  }

  static async createAddressComment(address, comment) {
    const payload = {
      comment,
      address,
    };
    const path = `${window.ambulnzConfig.tenantedRestApiV2Url}/AddressComments`;

    let response;
    try {
      response = await ApiService.post(path, payload);
    } catch (e) {
      console.log('failed to create address comment', e);
      response = {};
    }

    return response.json();
  }

  static async getAddressComment(address) {
    const path = `${window.ambulnzConfig.tenantedRestApiV2Url}/AddressComments/search`;
    let response;
    try {
      response = await ApiService.post(path, address);
    } catch (e) {
      console.log('failed to get address comment', e);
      response = {};
    }

    return response.json();
  }

  static async deleteAddressComment(id) {
    const path = `${window.ambulnzConfig.tenantedRestApiV2Url}/AddressComments/${id}`;

    let response;
    try {
      response = await ApiService.delete(path);
    } catch (e) {
      console.log('failed to delete address comment', e);
      response = {};
    }

    return response.json();
  }
}

export default CommentService;
