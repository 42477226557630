import schema from 'schm';
import translate from 'schm-translate';
import computed from 'schm-computed';

const address = schema(
  {
    street1: String,
    city: String,
    state: String,
    postalCode: String,
    country: String,
  },
  translate({
    street1: '0.places.0.address.street1',
    city: '0.places.0.address.city',
    state: '0.places.0.address.state',
    postalCode: '0.places.0.address.postalCode',
    country: '0.places.0.address.country',
  }),
  computed({
    full: values => `${values.street1}, ${values.city}, ${values.postalCode}, ${values.state}`,
  }),
);

export default address;
