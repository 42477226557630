import React from 'react';

export default function (props) {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
      style={{ ...props.style, pointerEvents: props.disabled ? 'none' : 'auto', cursor: 'pointer' }}
    >
      <path
        id="Vector"
        d="M0 14.1666V0.166626H7L7.5 2.16663H12V10.1666H7L6.5 8.16663H1.5V14.1666H0ZM8.16667 8.66663H10.5V3.66663H6.33333L5.83333 1.66663H1.5V6.66663H7.66667L8.16667 8.66663Z"
        fill="#859DA7"
      />
    </svg>
  );
}
