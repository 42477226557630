import withValidations from '../common/withValidations';
import styledFrame from './ServiceLevel.frame';

const validations = {
  questionMode: (_, props) => {
    if (props.showQuestions) {
      return 'All service type questions must be answered';
    }
    return null;
  },
  serviceLevel: (value, props) => {
    if (!props.serviceId) {
      return 'Service Level must be specified';
    }

    return null;
  },
  patientWeight: (value, props) => {
    if (props.weightHeightRequired && (!value || value.length === 0)) {
      return 'Patient must have a weight';
    }
    if(value && Number.isNaN(value)){
      return 'Must be a numeric value';
    }
    return null;
  },
  patientHeight: (value, props) => {
    if (props.weightHeightRequired && (!value || value.length === 0)) {
      return 'Patient must have a height';
    }
    return null;
  },
};

export default withValidations(validations)(styledFrame);
