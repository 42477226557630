import '@ambulnz/amb-autocomplete';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import AmbExpansionPanel from '../../common/AmbExpansionPanel';
import Condition from '../../common/icons/Condition';
import AmbInputLabel from '../../common/AmbInputLabel';
import { theme, palette } from '../../../theme';

const getSubLabelColor = ({ error, disabled }) => {
  if (error) {
    return palette.primary.red;
  }

  if (disabled) {
    return palette.primary.disabled;
  }

  return palette.primary.orange;
};

const getHelperTextColor = ({ error, disabled }) => {
  if (error) {
    return palette.primary.red;
  }

  if (disabled) {
    return palette.primary.disabled;
  }

  return palette.primary.helperText;
};

const StyledHelperText = styled.p`
  color: ${props => getHelperTextColor(props)};
  margin: 0;
  font-size: 12px;
  text-align: left;
  margin-top: 8px;
  min-height: 1em;
  line-height: 1em;
`;

const SubLabel = styled.span`
  color: ${props => getSubLabelColor(props)};
  padding-top: 5px;
  line-height: 15px;
  display: block;
`;

const HelperText = ({ subLabel, error, disabled, id }) => {
  return (
    <>
      <StyledHelperText id={id} error={error} disabled={disabled || null}>
        {subLabel && (
          <SubLabel error={error} disabled={disabled || null}>
            {subLabel}
          </SubLabel>
        )}
      </StyledHelperText>
    </>
  );
};

const Wrapper = styled.div`
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  position: relative;
  font-size: 14px;
  padding: 6px 0 7px;
  width: 100%;

  &::before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: '';
    position: absolute;
    transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.42);
  }

  &::after {
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    content: '';
    position: absolute;
    transform: ${props => (props.isFocused ? 'scaleX(1)' : 'scaleX(0)')};
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: ${props => (props.isError ? '#f44336' : '#004053')};
  }
`;

const StyledTextArea = styled.textarea`
  font: inherit;
  color: currentColor;
  border: 0;
  margin: 0;
  padding: 0;
  resize: vertical;
  display: block;
  min-width: 480px;
  outline: none;
  box-sizing: content-box;
  background: none;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
`;

const StyledFormControl = styled.div`
  margin: 0;
  border: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
`;

const TextArea = ({ fullWidth, id, onChange, onBlur, disabled, value, error, subLabel }) => {
  const [isFocused, setIsFocused] = useState(false);

  const [hasError, setHasError] = useState(false);
  const [subLabelValue, setSubLabelValue] = useState(subLabel);

  useEffect(() => {
    setHasError(!!error);
  }, [error]);

  useEffect(() => {
    if (hasError) {
      setSubLabelValue(subLabel);
    }
  }, [hasError, subLabel]);

  const handleBlur = e => {
    onBlur(e);
    setIsFocused(false);
  };

  return (
    <>
      <Wrapper isFocused={isFocused} isError={error}>
        <div style={{ position: 'relative', width: '100%' }}>
          <StyledTextArea
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            type="text"
            onChange={onChange}
            id={id}
            style={{ width: fullWidth ? '100%' : 'auto' }}
            value={value}
            autoFocus
            disabled={disabled}
          />
        </div>
      </Wrapper>
      <HelperText subLabel={subLabelValue} error={hasError} disabled={disabled} id={`${id}-helper-text`} />
    </>
  );
};

class ChiefComplaintFrame extends React.Component {
  state = {
    expanded: false,
  };

  constructor(props) {
    super(props);
    this.autocompleteEl = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.shouldCollapse) {
      this.setState({ expanded: false });
    }

    if (this.props.agencyRequiresChiefComplaint !== nextProps.agencyRequiresChiefComplaint) {
      this.props.validateAll(nextProps);
    }

    if (
      nextProps.triggerValidation !== this.props.triggerValidation &&
      nextProps.triggerValidation &&
      nextProps.agencyRequiresChiefComplaint
    ) {
      this.props.validateAll(nextProps);
    }

    this.props.setSaveErrorMessage(nextProps.errors);
  }

  componentDidUpdate() {
    if (this.autocompleteEl.current && this.autocompleteEl.current.eventListeners().length === 0) {
      this.autocompleteEl.current.addEventListener('valueChange', this.props.handleChange);
    }
  }

  componentWillUnmount() {
    this.props.setSaveErrorMessage({
      chiefComplaintId: null,
    });
    if (this.autocompleteEl.current) {
      this.autocompleteEl.current.removeEventListener('valueChange', this.props.handleChange);
    }
  }

  togglePanel = () => {
    if (this.state.expanded) {
      this.props.validateAll(this.props);
    }
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    return (
      <AmbExpansionPanel
        icon={<Condition style={{ height: 16, width: 16, marginTop: 2, fill: theme.palette.icon.primary }} />}
        title={this.props.title}
        expanded={this.state.expanded}
        onTogglePanel={this.togglePanel}
        id="ChiefComplaintPanel"
        error={this.props.hasErrors}
      >
        <div style={{ paddingTop: 20, width: '100%', display: 'inline-flex', flexDirection: 'column' }}>
          <AmbInputLabel
            htmlFor="ChiefComplaintSelect"
            style={{ fontSize: 15, color: theme.palette.grey.grey80 }}
            shrink
            required={this.props.agencyRequiresChiefComplaint}
          >
            Chief Complaint
          </AmbInputLabel>
          {this.props.chiefComplaintList.length && (
            <amb-autocomplete
              ref={this.autocompleteEl}
              id="ChiefComplaintSelect"
              value={this.props.chiefComplaintId}
              options={JSON.stringify(this.props.chiefComplaintList)}
              placeholder="Select chief complaint"
              disabled={this.props.disabled || null}
              error={this.props.errors.chiefComplaintId}
            />
          )}
          {this.props.showDescription && (
            <>
              <hr
                style={{
                  marginTop: 15,
                  marginBottom: 5,
                  backgroundColor: '#fafafa',
                  height: 1,
                  margin: 0,
                  border: 'none',
                }}
              />
              <StyledFormControl>
                <AmbInputLabel
                  htmlFor="chiefComplaintName"
                  shrink
                  required={this.props.agencyRequiresChiefComplaint}
                  style={{ fontSize: 15, color: theme.palette.grey.grey80 }}
                  error={!!this.props.errors.chiefComplaintName}
                >
                  Chief Complaint Description
                </AmbInputLabel>
                <TextArea
                  name="chiefComplaintName"
                  id="chiefComplaintName"
                  fullWidth
                  onChange={this.props.handleDescriptionChange}
                  required={this.props.isDispatcher}
                  value={this.props.chiefComplaintName}
                  onBlur={this.props.handleDescriptionBlur}
                  disabled={this.props.disabled || null}
                  error={!!this.props.errors.chiefComplaintName}
                  subLabel={this.props.errors.chiefComplaintName}
                />
              </StyledFormControl>
            </>
          )}
        </div>
      </AmbExpansionPanel>
    );
  }
}

ChiefComplaintFrame.propTypes = {
  shouldCollapse: PropTypes.bool.isRequired,
  triggerValidation: PropTypes.bool.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  errors: PropTypes.shape().isRequired,
  validateAll: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDescriptionBlur: PropTypes.func.isRequired,
  handleDescriptionChange: PropTypes.func.isRequired,
  chiefComplaintList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  chiefComplaintId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setSaveErrorMessage: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  showDescription: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  chiefComplaintName: PropTypes.string,
  otherDisabled: PropTypes.bool.isRequired,
  otherTitle: PropTypes.string.isRequired,
};

ChiefComplaintFrame.defaultProps = {
  chiefComplaintName: '',
  chiefComplaintId: null,
};

export default ChiefComplaintFrame;
