import { ETA_TYPES } from 'enums';

export const parseForAnalytics = (state, payload) => {
  const callTypeName = state.service.callTypeOptions?.find(ct => ct.key === state.service.callTypeId)?.value;
  const isAppointment = state.eta.etaType === ETA_TYPES.APPOINTMENT;
  const mileageOptions = payload.offeredEtas?.map(eta => eta.totalDrive) || [];

  return {
    tripId: state.requests.request.id,
    transportReason: callTypeName,
    isAppointment,
    isError: !payload.offeredEtas || payload.offeredEtas.length === 0,
    mileage: mileageOptions,
  };
};
